
const state = {
    tags: [],
}

const mutations = {
    //添加标签
    ADD_TAGS: (state, list) => {
        state.tags = list
    },
    //删除全部标签
    CLEARALL_TAGS: (state) => {
        state.tags = []
    },
    //删除指定标签
    DELITEM_TAG: (state, name) => {
        let index = state.tags.findIndex(n => n.name == name);
        state.tags.splice(index, 1)
    },
}

const actions = {

}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
