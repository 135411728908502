<template>
  <div class="nodata_box">
    <!-- <img :src="img" alt="" class="icon"> -->
    <p>空空如也～</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // img: require("@/assets/png/nodata.png")
    }
  }
}
</script>
<style scoped lang="scss">
.nodata_box {
  width: 100%;
  height: 100%;
  // padding-top: 50px;
  display: flex;
  color: rgb(68,65,91);
    font-size: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon {
    width: 228px;
  }
}
</style>
