import axios from "@/utils/request";

/************************* 主页home *************************/


// 模块 参数 moduleName=动漫 或 漫画
export function moduleList(data) {
  return axios.get(`modules/list`, { params: data });
}

// 获取模块详情
// 参数 subModuleId=X pageNumber=1 pageSize=10
export function moduleListDetails(data) {
  return axios.get(`modules/detail`, { params: data });
}

// 获取随便看看
export function getJustLookList(data) {
  return axios.get(`cartoon/moduleMedia`, {
    params: data
  });
}

// 模块内专题点击“更多”
// 参数 sectionID=X simpleSort=X pageNumber=1 pageSize=10
export function moduleListMore(data) {
  return axios.get(`modules/section`, { params: data });
}

// 模块内专题更多new
// 参数 sectionID=X simpleSort=X pageNumber=1 pageSize=10
export function getModuleListMore(data) {
  return axios.get(`cartoon/sectionMedia`, { params: data });
}

export function getModuleList(data) {
  return axios.get(`vid/module`, { params: data });
}

// 获取预售详情
export function getPresaleList(data) {
  return axios.get(`video_gold_coin/list`, { params: data });
}
