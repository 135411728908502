(function (global) {
    var startY = 0;
    var enabled = false;
    var supportsPassiveOption = false;
    try {
        var opts = Object.defineProperty({}, "passive", {
            // eslint-disable-next-line getter-return
            get: function () {
                supportsPassiveOption = true
            }
        });
        window.addEventListener("test", null, opts)
    } catch (e) {
        console.log(e)
    }
    var handleTouchmove = function (evt) {
        var el = evt.target;
        var zoom = window.innerWidth / window.document.documentElement.clientWidth;
        if (evt.touches.length > 1 || zoom !== 1) {
            return
        }
        while (el !== document.body && el !== document) {
            var style = window.getComputedStyle(el);
            if (!style) {
                break
            }
            if (el.nodeName === "INPUT" && el.getAttribute("type") === "range") {
                return
            }
            if (el.querySelector('.oepnX')) {
                return;
            }
            var scrolling = style.getPropertyValue("-webkit-overflow-scrolling");
            var overflowY = style.getPropertyValue("overflow-y");
            var height = parseInt(style.getPropertyValue("height"), 10);
            var isScrollable = scrolling === "touch" && (overflowY === "auto" || overflowY === "scroll");
            var canScroll = el.scrollHeight > el.offsetHeight;
            if (isScrollable && canScroll) {
                var curY = evt.touches ? evt.touches[0].screenY : evt.screenY;
                var isAtTop = startY <= curY && el.scrollTop === 0;
                var isAtBottom = startY >= curY && el.scrollHeight - el.scrollTop - 10 <= height;
                if (isAtTop || isAtBottom) {
                    evt.preventDefault()
                }
                return
            }
            el = el.parentNode
        }
        evt.preventDefault()
    };
    var handleTouchstart = function (evt) {
        startY = evt.touches ? evt.touches[0].screenY : evt.screenY
    };
    var enable = function () {
        window.addEventListener("touchstart", handleTouchstart, supportsPassiveOption ? {passive: false} : false);
        window.addEventListener("touchmove", handleTouchmove, supportsPassiveOption ? {passive: false} : false);
        enabled = true
    };
    var disable = function () {
        window.removeEventListener("touchstart", handleTouchstart, false);
        window.removeEventListener("touchmove", handleTouchmove, false);
        enabled = false
    };
    var isEnabled = function () {
        return enabled
    };
    var testDiv = document.createElement("div");
    document.documentElement.appendChild(testDiv);
    testDiv.style.WebkitOverflowScrolling = "touch";
    var isScrollSupported = "getComputedStyle" in window && window.getComputedStyle(testDiv)["-webkit-overflow-scrolling"] === "touch";
    document.documentElement.removeChild(testDiv);
    if (isScrollSupported) {
        enable()
    }
    var iNoBounce = {enable: enable, disable: disable, isEnabled: isEnabled, isScrollSupported: isScrollSupported};
    if (typeof module !== "undefined" && module.exports) {
        module.exports = iNoBounce
    }
    if (typeof global.define === "function") {
        (function (define) {
            define("iNoBounce", [], function () {
                return iNoBounce
            })
        })(global.define)
    } else {
        global.iNoBounce = iNoBounce
    }
})(this);
