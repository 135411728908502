<template>
  <van-tabbar  @change="change" v-model="active" :active-color="tabColor()" inactive-color="#999999" class="tabbar-warp" :safe-area-inset-bottom="true">
    <van-tabbar-item name="Home" to="/home">
     <template #icon>
      <div class="bottomIcon">
       <template v-if="active === 'Home'">
        <div
          class="isActive flex-aic"
          @click.stop="backTop"
          v-if="scrollDistance"
        >
         <img :src="backTopIcon" alt="top">
         <span>顶部</span>
        </div>
        <template v-else>
          <div class="isActive flex-aic">
           <img :src="homeIconActive" alt="isAcg">
           <span>ACG</span>
          </div>
        </template>
       </template>
       <template v-else>
        <div class="isActive flex-aic">
         <img :src="homeIcon" alt="acg" />
         <span>ACG</span>
        </div>
       </template>
      </div>
     </template>
    </van-tabbar-item>
    <van-tabbar-item name="Community" to="/community">
     <template #icon>
      <div class="bottomIcon">
       <template v-if="active === 'Community'">
        <div
          class="isActive flex-aic"
          @click.stop="backTop"
          v-if="scrollDistance"
        >
         <img :src="communityBackTop" alt="top">
         <span>顶部</span>
        </div>
        <template v-else>
         <div class="isActive flex-aic">
          <img :src="communityIconActive" alt="isAcg">
          <span>圈子</span>
         </div>
        </template>
       </template>
       <template v-else>
        <div class="isActive flex-aic">
         <img :src="communityIcon" alt="acg" />
         <span>圈子</span>
        </div>
       </template>
      </div>
     </template>
    </van-tabbar-item>
    <van-tabbar-item to="/original"  name="Original">
      <template #icon="props">
        <img :class="props.active ? 'originalIconActive': 'originalIcon' " :src="props.active ? yjIconActive: yjIcon" alt=""/>
      </template>
    </van-tabbar-item>
    <!-- <van-tabbar-item :icon="active == 'Welfare' ? welfareIconActive  : welfareIcon" name="Welfare" to="/welfare">福利
    </van-tabbar-item> -->
<!--    <van-tabbar-item :icon="active == 'Loufeng' ? recreationIconActive  : recreationIcon" name="Loufeng" to="/loufeng">娱乐-->
<!--    </van-tabbar-item>-->
    <van-tabbar-item name="VideoModule" to="/videoModule">
     <template #icon>
      <div class="bottomIcon">
       <template v-if="active === 'VideoModule'">
        <div
          class="isActive flex-aic"
          @click.stop="backTop"
          v-if="scrollDistance"
        >
         <img class="video" :src="videoBackTop" alt="top">
         <span>顶部</span>
        </div>
        <template v-else>
         <div class="isActive flex-aic">
          <img :src="recreationIconActive" alt="isAcg">
          <span>视频</span>
         </div>
        </template>
       </template>
       <template v-else>
        <div class="isActive flex-aic">
         <img :src="recreationIcon" alt="acg" />
         <span>视频</span>
        </div>
       </template>
      </div>
     </template>
    </van-tabbar-item>
    <!-- <van-tabbar-item to="/bookshelf" :icon="active == 'Bookshelf' ? bookshelfIconActive  : bookshelfIcon" name="Bookshelf">书架
    </van-tabbar-item> -->
    <van-tabbar-item :icon="active === 'Mine'? mineIconActive : mineIcon" to="/mine" name="Mine">我的</van-tabbar-item>
  </van-tabbar>
</template>
<script>
import {mapGetters} from "vuex";
// import {noRedMessage} from "@/api/app";
import {Tabbar, TabbarItem} from "vant";
import {firstUpperCase} from "@/utils";
import {getLoufengUrl} from "@/utils/getConfig";
// import {getSessionItem} from "@/utils/longStorage";

export default {
  name: "ShopCats",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  computed: {
    ...mapGetters({
      keepScroll: 'keepScroll',
      activeIndex: 'activeIndex',
    }),
   scrollDistance() {
    const scrollData = this.keepScroll[`${this.$route.path}`] || [];
    const scrollItem = scrollData.find(item => item.scrollObj === this.activeIndex);
    return scrollItem ? scrollItem.scrollDistance : 0;
   },
  },
  data() {
    return {
      active: "Home",
      backTopIcon: require("@/assets/png/backTop.png"),
      communityBackTop: require("@/assets/png/communityBackTop.png"),
      videoBackTop: require("@/assets/png/videoBackTop.png"),
      homeIcon: require("@/assets/png/homeIcon.png"),
      homeIconActive: require("@/assets/png/homeIconActive.png"),
      communityIcon: require("@/assets/png/communityIcon.png"),
      communityIconActive: require("@/assets/png/communityIconActive.png"),
      // welfareIcon: require("@/assets/png/welfareIcon.png"),
      // welfareIconActive: require("@/assets/png/welfareIconActive.png"),
      // bookshelfIcon: require("@/assets/png/bookshelfIcon.png"),
      // bookshelfIconActive: require("@/assets/png/bookshelfIconActive.png"),
      yjIcon: require("@/assets/png/yjHome.png"),
      yjIconActive: require("@/assets/png/yjHomeActive.png"),
      recreationIcon: require("@/assets/png/recreation.png"),
      recreationIconActive: require("@/assets/png/recreationActive.png"),
      mineIcon: require("@/assets/png/mineIcon.png"),
      mineIconActive: require("@/assets/png/mineIconActive.png"),
      loufengUrl: null,
    };
  },
  created() {
    this.refreshRoute(this.$route.path);
    this.loufengUrl = getLoufengUrl();
  },
  methods: {
    backTop() {
     // console.log(this.keepScroll, this.activeIndex);
     let dom = document.getElementById(this.activeIndex);
     setTimeout(() => {
      dom?.scrollTo({
       top: 0,
       behavior: 'smooth',
      });
     }, 500);
    },
    tabColor(){
      if(this.active === 'Home'){
        return '#502DA5';
      }

      if(this.active === 'Community'){
        return '#1A75F5';
      }

      if(this.active === 'VideoModule'){
        return '#B84EFF';
      }

      if(this.active === 'Mine'){
        return '#FF888A';
      }
    },
    async queryMsgHasNew() {
     await this.$store.dispatch('user/updateMsgHasNew');
     this.msgHasNew = this.$store.getters.msgHasNew;
    },
    change() {
     if (this.active === 'Mine') {
       this.queryMsgHasNew();
      }
    },
    // 刷新路由
    refreshRoute(path) {
      // if (path.match(/\/(\S*)\//)) {
      //   this.active = firstUpperCase(path.match(/\/(\S*)\//)[1]);
      // } else {
      //   this.active = firstUpperCase(this.$route.name);
      // }
      if (path.match(/\/(\S*)\//)) {
        this.active = path.match(/\/(\S*)\//)[1];
      } else {
        this.active = this.$route.name;
      }
    },
    // onMovies() {
    //   let path = getSessionItem('moviesPath') || '/movies';
    //   this.$router.replace(path);
    // },
    // onCommunity() {
    //   let path = getSessionItem('communityPath') || '/community';
    //   this.$router.replace(path);
    // },
    // refreshPage() {
    //   this.$store.commit('commnity/CHANGE_REFRESHSTA', true);
    // },
    // async noRedMessage() {
    //   let res = await this.$Api(noRedMessage);
    //   if (res && res.code == 200) {
    //     this.$store.commit('user/SET_UNREADMSG', res.data)
    //   }
    // }
  },
    watch:{
        $route(to, from){
            this.refreshRoute(this.$route.path);
        }
    }
};
</script>
<style scoped lang="scss">
// .active-background {
//   background-color: #000 !important;
// }
// .active-background::before {
//   content: '' !important;
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 100%;
//   height: 120px;
//   background-image: url('~@/assets/png/footerActive.png') !important;
//   background-repeat: no-repeat;
//   background-size: 100%;
// }
/deep/ .van-tabbar {
 background: transparent;
}
.tabbar-warp {
  width: 100%;
  height: 88px;
  // padding-top: 5px;
  box-sizing: border-box;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  border: solid 2px #FFFFFF;
  background: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0px -3px 7.6px 0px rgba(169, 167, 167, 0.25);
  backdrop-filter: blur(5px);

  /deep/ .van-tabbar-item {
    height: 65px;
    margin-top: 0;
    z-index: 9;
    position: relative;
  }
}

.originalIcon{
  width: 54px;
  height: 36px;
  margin-top: 10px;
}

.originalIconActive{
  width: 74.25px;
  height: 49.5px;
  margin-top: 10px;
}

//.tabbar-warp::before {
//  content: '';
//  position: absolute;
//  left: 0;
//  bottom: 0;
//  width: 100%;
//  height: 100px;
//  background-image: url('~@/assets/png/footerTab.png');
//  background-repeat: no-repeat;
//  background-size: 100%;
//}
/deep/ .van-info {
  top: -4px;
  border: none;
}
/deep/ .van-tabbar-item__icon {
  font-size: 30px;
  margin-bottom: 5px;
 .bottomIcon {
  margin-top: 4px;
  .isActive {
   flex-direction: column;
   img {
    width: 30px;
    height: 30px;
   }
   .video {
    width: 15.73px;
    height: 19.67px;
   }
   span {
    margin-top: 6px;
    font-size: 10px;
    line-height: 14px;
   }
  }
 }
}

/deep/ .van-tabbar-item__text {
  font-size: 10px;
  line-height: 14px;
  // color: #C6C6C6;
}
/deep/ .van-tabbar-item {
  // padding: 0 0 34px;
  box-sizing: border-box;
}

///deep/ .van-tabbar-item:nth-child(3) {
//  .van-icon__image img {
//    width: 54px !important;
//    height: 36px !important;
//  }
//}

/deep/ .van-tabbar-item__icon .van-icon__image {
  background: transparent !important;
  width: 30px;
  height: 30px;
}



.van-tabbar-item--active {
  background-color: transparent;
  /deep/ .van-tabbar-item__text {
    // color: #000 !important;
    font-weight: bolder !important;
  }
}

/deep/ .van-tabbar-item--active:nth-child(1)::before{
    content: '';
    position: absolute;
    left: -9px;
    bottom: -22px;
    width: 90px;
    height: 63px;
    background-image: url('~@/assets/png/acgShadowIcon.png');
    background-repeat: no-repeat;
    background-size: 100%;
}


/deep/ .van-tabbar-item--active:nth-child(2)::before{
  content: '';
  position: absolute;
  left: -9px;
  bottom: -22px;
  width: 90px;
  height: 63px;
  background-image: url('~@/assets/png/circleShadowIcon.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

/deep/ .van-tabbar-item--active:nth-child(4)::before{
  content: '';
  position: absolute;
  left: -6px;
  bottom: -22px;
  width: 90px;
  height: 63px;
  background-image: url('~@/assets/png/videoShadowIcon.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

/deep/ .van-tabbar-item--active:nth-child(5)::before{
  content: '';
  position: absolute;
  left: -6px;
  bottom: -22px;
  width: 90px;
  height: 63px;
  background-image: url('~@/assets/png/mineShadowIcon.png');
  background-repeat: no-repeat;
  background-size: 100%;
}

[class*="van-hairline"]::after {
  border: none;
}
</style>
