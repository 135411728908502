<template>
  <div class="main" @click.prevent>
    <keep-alive :include="include">
      <router-view />
    </keep-alive>
    <TabBar />
    <!-- 预售 + 支付尾款 浮窗 -->
    <div class="persalebox" v-if="appConfig.advanceStatus.activityStatus">
      <img
        v-if="isShowPersaleFC == 'ys'"
        @click="$router.push('/presale')"
        src="@/assets/png/presale/persalepop.png"
        alt=""
      />
      <img
        v-else-if="isShowPersaleFC == 'pay'"
        @click="$router.push('/presale')"
        src="@/assets/png/presale/paypop.png"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import TabBar from "@/components/Tabbar";
import { getPresaleList } from "@/api/home";
import { judgeTime } from "@/utils/index";
export default {
  name: "Main",
  components: {
    TabBar,
  },
  data() {
    return {
      include: ["VideoModule", "Original", "Home"],
      activityDetail: {},
      appConfig: {},
      isShowPersaleFC: "", //浮窗展示
    };
  },
  created() {
    this.appConfig = this.$store.getters.appConfig;
    this.getPresaleData();
  },
  methods: {
    async getPresaleData() {
      let req = {
        pageNumber: 1,
        pageSize: 1,
      };
      let ret = await this.$Api(getPresaleList, req);
      // console.log(ret, "-=--==-==============");
      if (ret && ret.code == 200) {
        this.activityDetail = ret.data.activityDetail;
        // 预售浮窗
        if (
          this.activityDetail.status == 0 ||
          this.activityDetail.status == 1
        ) {
          this.isShowPersaleFC = "ys";
        } else if (
          (this.activityDetail.status == 2 ||
            this.activityDetail.status == 3) &&
          judgeTime(this.activityDetail.startTime)
        ) {
          //支付尾款浮窗
          this.isShowPersaleFC = "pay";
        } else if (
          (this.activityDetail.status == 2 ||
            this.activityDetail.status == 3) &&
          !judgeTime(this.activityDetail.startTime)
        ) {
          //预售
          this.isShowPersaleFC = "ys";
        } else {
          this.isShowPersaleFC = "";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: calc(100% - 28px);
  //overflow-y: auto;
}
.persalebox {
  position: fixed;
  bottom: 185px;
  right: 18px;
  img {
    width: 60px;
    animation: shake 1.5s infinite;
  }
}
@keyframes shake {
  0%,
  65% {
    transform: rotate(0deg) scale(1);
  }
  70% {
    transform: rotate(0deg) scale(1.1);
  }
  75% {
    transform: rotate(6deg) scale(1.1);
  }
  80% {
    transform: rotate(-6deg) scale(1.1);
  }
  85% {
    transform: rotate(6deg) scale(1.1);
  }
  90% {
    transform: rotate(-6deg) scale(1.1);
  }
  95% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
</style>
