<template>
  <div class="privilegeInfo">
    <!--user-->
    <div class="user_box">
       <div class="user">
        <div class="user_left">
          <div class="avatar">
            <ImgDecypt :src="userInfo.portrait" round :key="userInfo.portrait"/>
          </div>
          <div class="userInfo">
            <h2>{{ userInfo.name || "未知" }}</h2>
            <p v-if="!isVip">当前未开通VIP</p>
            <p v-else class="vip">{{ formatDate() }}</p>
          </div>
        </div>
        <div class="vip_right" v-if="!isVip">
          <span @click="$router.push('/memberCentre?t=vip')">立即开通</span>
        </div>
        
         <div class="vip_right" v-if="isVip && userInfo.vipLevel < 2">
          <span  @click="$router.push('/memberCentre?t=vip')">去升级</span>
        </div>

      </div>
      <div class="activity" v-if="countdownSecInfo.countdownSec" @click="$router.push('/memberCentre?t=vip')">
        {{ countdownSecInfo.copywriting || "新人专享倒计时" }} {{ countdownSecInfo.timeStr }}
      </div>
    </div>
    <!--特权-->
    <div class="privilege" v-if="list.length">
      <svg-icon icon-class="privilege"/>
      <div class="privilege_content oepnX">
        <div class="privilege_item" v-for="item in list" :key="item.id">
          <div class="privilege_item_bg">
            <ImgDecypt :src="item.img" round class="icon"/>
          </div>
          <span>{{ item.privilegeName }}</span>
        </div>
      </div>
    </div>

    <AdvSwiper :advList="advList" v-if="advList.length" :hasPagination="false" class="adv"/>
  </div>
</template>

<script>
import ImgDecypt from "@/components/ImgDecypt";
import AdvSwiper from "@/components/AdvSwiper";
import {mapGetters, mapState} from "vuex";
import {getVipProductBenefits} from "@/utils/getConfig";
import { AdType, getAdItem } from "@/utils/getConfig";

export default {
  name: "oricilegeInfo",
  components: {
    ImgDecypt,
    AdvSwiper: AdvSwiper,
  },
  data() {
    return {
      list: []
    }
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
    ...mapState({
      countdownSecInfo: state => state.user.countdownSecInfo
    }),
    isVip() {
      let vipExpireDate = this.userInfo?.vipExpireDate;
      if ((this.userInfo.isVip && this.userInfo.vipLevel > 0) || (this.checkTime(vipExpireDate) && this.userInfo.isVip)) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.advList = getAdItem(AdType.MOVIES_DOMESTIC);
    this.list = getVipProductBenefits()
    this.formatDate();
  },
  methods: {
    // 显示vip类型
    showVipType(level) {
      let vipStr = "";
      if (level > 1) {
        vipStr = "超级会员"
      } else {
        vipStr = "普通会员"
      }
      return vipStr;
    },
    // 校验时间
    checkTime(timer) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(timer).getTime();
      if (timer && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    // 日期
    formatDate() {
      let vipExpireDate = this.userInfo?.vipExpireDate;
      if (!vipExpireDate) return '';
      let level = this.userInfo?.vipLevel
      let dateago = new Date(vipExpireDate);
      let dateStr = dateago.getFullYear() + '/' + (dateago.getMonth() + 1) + '/' + dateago.getDate();
      return this.showVipType(level) + " " + dateStr;

    },
  }

}
</script>

<style scoped lang="scss">
.privilegeInfo {

  .adv {
    margin: 12px 16px 7px;
    /deep/ .swiper-container {
      height: 83px;
    }
    /deep/ .swiperSlide {
      background: #00000000;
    }
    /deep/ img {
      height: 100%;
      object-fit: fill;
      border-radius: 10px;
    }
  }
  
  .activity {
    margin-top: 26px;
    border-radius: 40px; /* 圆角属性测试 */
    border: 1.5px solid #f5b771;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    color: #f9b776;
  }

  .user_box {
    border-radius: 9px;
    // background: url("../../../assets/png/vip_m_bg.png");
    background-size: 100%;
    margin: 20px 10px 13px;
    padding: 17.4px 18px;

    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .user_left {
        display: flex;
        align-items: center;

        .avatar {
          width: 51px;
          height: 51px;
        }

        .userInfo {
          display: flex;
          padding-left: 7px;
          flex-direction: column;
          justify-content: space-between;

          h2 {
            font-size: 16px;
            max-width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          p {
            font-size: 14px;
            color: #e0e0e0;
          }

          .vip {
            color: #f5bc78;
          }
        }
      }

      .vip_right {
        width: 96px;
        height: 32px;
        background: linear-gradient(to left, #f5b771, #f7d599);
        border-radius: 16px;
        text-align: center;
        line-height: 32px;
        font-size: 16px;
        color: #151515;
        font-weight: 500;

      }
    }
  }

  .privilege {
    svg {
      margin: 0 25px;
      width: 140px;
      height: 30.1px;
    }

    .privilege_content {
      margin-top: 12.4px;
      // margin-bottom: 27px;
      overflow-x: scroll;
      white-space: nowrap;
      .privilege_item {
        text-align: center;
        display: inline-block;
        padding-left: 36.5px;
        color: #686869;

        .privilege_item_bg {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 5px;

          .icon {
            width: 40px;
            height: 40px;
          }
        }


      }

      .privilege_item:last-child {
        padding-right: 10px;
      }

    }
  }
}
</style>
