<template>
 <div class="img_Decypt">
  <lazy-component class="laz_com" v-if="isLaz">
   <LazyImg
     :src="src"
     @load="onload"
     :isNeedDecypt="isNeedDecypt"
     :isSplash="isSplash"
     :round="round"
   />
   <slot/>
  </lazy-component>
  <div class="laz_com" v-else>
   <LazyImg
     :src="src"
     @load="onload"
     :isNeedDecypt="isNeedDecypt"
     :isSplash="isSplash"
     :round="round"
     :isLazy="isLaz"
   />
   <slot/>
  </div>
 </div>
</template>
<script>
import LazyImg from "@/components/LazyImg";

export default {
 props: {
  src: {
   type: String,
   required: true,
   default() {
    return "";
   },
  },
  isNeedDecypt: {
   //是否需要解密
   type: Boolean,
   required: false,
   default() {
    return true;
   },
  },
  isSplash: {
   //是否来自启动页
   type: Boolean,
   required: false,
   default() {
    return false;
   },
  },
  round: {
   //图片是否显示为圆形
   type: Boolean,
   required: false,
   default() {
    return false;
   },
  },
  isLaz: {
   //是否启用懒加载，默认启用，可传入false不启用懒加载，会遇到一些特殊的业务，用懒加载无法实现，所以预留了一下这个参数
   type: Boolean,
   required: false,
   default() {
    return true;
   },
  },
 },
 components: {
  LazyImg,
 },
 methods: {
  onload() {
   this.$emit("load");
  },
 },
};
</script>
<style scoped lang="scss">
.img_Decypt {
 height: 100%;

 .laz_com {
  height: 100%;
 }
}
</style>
