import {getLocalItem, getSessionItem, setLocalItem} from "@/utils/longStorage";
import {getAnnounce} from "@/api/movies";
import {Api} from "@/utils";

const state = {
    baseUrl: getSessionItem("baseUrl") || "", //选线过后的api域名
    appConfig: sessionStorage.getItem("appConfig") || {},  //全局配置信息
    UID: getLocalItem("UID") || "",    //用户唯一ID
    keepAliveList: [], //需要缓存的页面
    globalLoading: false, // 全局loading
    marqueeList: sessionStorage.getItem("marqueeList") || [], // 跑马灯
    homeRefreshDisable: false, //首页下拉禁用
    modulesInfo: sessionStorage.getItem("modulesInfo") || {}, //全局tab信息
    activeIndex: 1, // 导航选中的当前Tab索引
    homeTabIndex: 0, // 首页tab下标
    caricatureTabIndex: 0, // 漫画tab下标
    animationTabIndex: 0, // 动漫tab下标
    novelTabIndex: 0, // 小说tab下标
    videoTabIndex: 0, // 视频tab下标
    originalTabIndex: 0, // 原创tab下标
    photoTabIndex: 0, // 写真tab下标
    keepScroll: {
        '/home': [],
        '/community': [],
        '/videoModule': [],
    },
    keepPathScroll: {
        '/home': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: true,
            scrollArr: [],
            curIndex: 0,
        },
        '/community': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: true,
            scrollArr: [],
            curIndex: 0,
        },
        '/searchResultPage': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: true,
            scrollArr: [],
            curIndex: 0,
        },
        '/novelDetailsPage': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: false,
            scrollArr: [],
            curIndex: 0,
        },
        '/audioBookDetails': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: false,
            scrollArr: [],
            curIndex: 0,
        },
        '/videoVaultPage': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: false,
            scrollArr: [],
            curIndex: 0,
        },
        '/videoModule': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: true,
            scrollArr: [],
            curIndex: 0,
        },
        '/original': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: true,
            scrollArr: [],
            curIndex: 0,
        },
        '/playletModule/shortVideo': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: true,
            scrollArr: [],
            curIndex: 0,
        },
        '/playletModule/playlet': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: false,
            scrollArr: [],
            curIndex: 0,
        },
        '/playletModule/bingeWatch': {
            className: 'my-refresh',
            scrollTop: 0,
            isTab: true,
            scrollArr: [],
            curIndex: 0,
        }
    },
    bankCard: {
        id: "",
        actName: "",
        act: "",
        bankCode: "",
        cardType: "",
    }, // 选中的银行卡信息
}

const mutations = {
    SET_HOMEINDEX(state, index) {
        state.homeTabIndex = index;
    },
    SET_CARICATUREINDEX(state, index) {
        state.caricatureTabIndex = index;
    },
    SET_ANIMATIONINDEX(state, index) {
        state.animationTabIndex = index;
    },
    SET_NOVELINDEX(state, index) {
        state.novelTabIndex = index;
    },
    SET_VIDEOINDEX(state, index) {
        state.videoTabIndex = index;
    },
    SET_ORIGINALINDEX(state, index) {
        state.originalTabIndex = index;
    },
    SET_PHOTOINDEX(state, index) {
        state.photoTabIndex = index;
    },
    SET_BASEURL(state, baseUrl) {
        state.baseUrl = baseUrl;
    },
    SET_CONFIG_DATA(state, data) {
        state.appConfig = data;
    },
    SET_MODULESINFO_DATA(state, data) {
        state.modulesInfo = data;
    },
    SET_UID(state, uid) {
        state.UID = uid
    },
    SET_KEEP: (state, value) => {
        state.keepAliveList = value
    },
    SET_LOADING: (state, value) => {
        state.globalLoading = value
    },
    SET_HOMEREFRESHDISABLE: (state, sta) => {
        state.homeRefreshDisable = sta
    },
    SET_MARQUEE_LIST: (state, list) => {
        state.marqueeList = list
    },
    SET_BANKCARD: (state, data) => {
        state.bankCard = data
    },
    SET_UPDATE_SCROLL: (state, data) => {
        if (data.keyIndex != undefined) {
            state.keepPathScroll[data.key][data.sonKey][data.keyIndex] = data.sonValue;
        } else {
            state.keepPathScroll[data.key][data.sonKey] = data.sonValue;
        }
    },
    SET_SCROLL_INFO: (state, data) => {
        state.keepPathScroll[data.key].curIndex = data.index;
    },
    // 设置当前的导航index
    SET_ACTIVE_INDEX(state, index) {
        state.activeIndex = index;
    },
    SET_SCROLL: (state, { key, scrollObj, scrollDistance }) => {
        if (!state.keepScroll[key]) {
            state.keepScroll[key] = [];
        }

        // 查找是否已经存在相同的 scrollObj
        const item = state.keepScroll[key].find(item => item.scrollObj === scrollObj);

        if (item) {
            // 如果已存在，则更新 scrollDistance
            item.scrollDistance = scrollDistance;
        } else {
            // 如果不存在，则添加新的 item
            state.keepScroll[key].push({ scrollObj, scrollDistance });
        }
    },
}

const actions = {
    GET_UID({commit}) {
        let len = 16;
        let radix = 16;
        var chars =
            "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
                ""
            );
        var uuid = [],
            i;
        radix = radix || chars.length;

        if (len) {
            // Compact form
            for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
        } else {
            // rfc4122, version 4 form
            var r;

            // rfc4122 requires these characters
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
            uuid[14] = "4";

            // Fill in random data. At i==19 set the high bits of clock sequence as
            // per rfc4122, sec. 4.1.5
            for (i = 0; i < 36; i++) {
                if (!uuid[i]) {
                    r = 0 | (Math.random() * 16);
                    uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
                }
            }
        }
        let uid = uuid.join("") + Date.now();
        setLocalItem("UID", uid)
        commit("SET_UID", uid)
    },
    async getMarquee({commit}) {
        let res = await Api(getAnnounce, undefined);
        if (res.code === 200) {
            sessionStorage.setItem("marqueeList",JSON.stringify(res.data.announcement))
            commit('SET_MARQUEE_LIST', res.data.announcement)
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
