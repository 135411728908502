<template>
    <div class="share-popup">
        <van-overlay :show="show" :z-index="2001">
            <div class="popup-content" @click="close">
                <div class="popup-box" @click.stop>
                    <div class="popup-title">
                        <div class="icon"></div>
                        <div class="right-box">
                            <div class="title">妖精动漫</div>
                            <div class="desc">遇见二次元性世界</div>
                        </div>
                    </div>


                    <div class="cover" v-if="postInfo.verticalCover">
                        <ImgDecypt :src="postInfo.verticalCover" :key="postInfo.verticalCover" />
                        <!-- <svg-icon class="play" icon-class="play"></svg-icon> -->
                        <img class="play" src="@/assets/png/playBtn.png"/>
                        <div class="coverMasking"></div>
                    </div>
                    <div class="cover" v-else>
                        <ImgDecypt :src="postInfo.cover" :key="postInfo.cover" />
                        <!-- <svg-icon class="play" icon-class="play"></svg-icon> -->
                        <img class="play" src="@/assets/png/playBtn.png"/>
                        <div class="coverMasking"></div>
                    </div>
<!--                    <div class="content">-->
<!--                        <div class="content-top">-->
<!--                            <div class="qrcode">-->
<!--&lt;!&ndash;                                <qrcode-vue :value="qrCodeUrl" :size="61" level="L"/>&ndash;&gt;-->
<!--                            </div>-->
<!--                            <div class="content-right">-->
<!--                                <div class="content-title">-->
<!--                                    推广码：-->
<!--                                    <span style="font-size: 24px">{{ promotionCode }}</span>-->
<!--                                </div>-->
<!--                                <div class="content-title" style="margin-top: 5px">-->
<!--                                    网址：-->
<!--                                    <font>{{ url }}</font>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="content-bottom">成功分享好友并下载 可获得会员奖励</div>-->
<!--                    </div>-->
                    <div class="content">
                        <div class="qrBox">
                            <div ref="qrCodeUrl"></div>
                        </div>
                        <div class="userInfo">
                            <div class="title">@{{userInfo.name}}</div>
                            <div class="desc">
                                每邀请<span>1人</span>送<span>1天</span>会员
                            </div>
                        </div>
                    </div>
                    <div class="btn-bottom">
                        <div class="copy" @click="copyLink(qrCodeUrl)">复制链接</div>
                        <div class="save" @click="save">保存图片</div>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import ImgDecypt from "@/components/ImgDecypt";
import QRCode from "qrcodejs2";
import { Toast } from 'vant';

export default {
    name: "index",
    components:{
        ImgDecypt
    },
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false,
        },
        postInfo:{
            type: Object,
            required: true,
            default: ()=>{
                return {};
            },
        }
    },
    data() {
        return {
            userInfo:{},
            qrCodeUrl: "",
            promotionCode: "",
            // videoInfos: {
            //     newsType: 'SP',
            // },
            // url: configStore.sourceInfo.LAND,
        };
    },
    beforeDestroy() {
      window.removeEventListener('popstate', this.handlePopstate);
    },
    mounted() {
        window.addEventListener('popstate', this.handlePopstate);
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if(this.userInfo){
            this.qrCodeUrl = this.userInfo.promoteURL;
            this.promotionCode = this.userInfo.promotionCode;
            this.generateCode(this.qrCodeUrl);
        }
    },methods:{
        // 监听 popstate 事件
        handlePopstate() {
          if (!this.$emit("close")) {
            this.close();
          }
        },
        close() {
            this.$emit("close");
        },
        copyLink(text){
            this.$copyText(text).then(
                (e) => {
                    Toast("复制成功");
                },
                (e) => {
                    Toast("复制失败");
                }
            );
        },
        save(){
            Toast("请手动截图保存")
        },
        // 生成二维码
        generateCode(url) {
            this.$nextTick(() => {
                this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: url,
                    width: 130,
                    height: 130,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.L,
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.share-popup {
    //width: 100%;
    //height: 100%;
    //position: fixed;
    //top: 0;
    //left: 0;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //background-color: rgba(0, 0, 0, 0.1);
    z-index: 999;
    .pop_title {
        width: 300px;
        height: 69px;
        //background: url('@/assets/images/png/videotitle.png') no-repeat;
        background-size: 100% 100%;
        .close {
            position: absolute;
            right: 0;
            bottom: 10px;
            //background: url('@/assets/images/png/close2.png') no-repeat;
            background-size: 100% 100%;
            width: 30px;
            height: 30px;
        }
    }

    .popup-content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .qrBox {
            width: 130px;
            height: 130px;
            //margin: 274px auto 21px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .popup-box {
        width: 300px;
        //height: 372px;
        padding: 0 0 20px 0;
        // background: linear-gradient(rgb(255, 240, 241), rgb(255, 255, 255), rgb(255, 255, 255));
        //background: url("@/assets/images/png/grounbg.png") no-repeat;
        //background-size: 100% 100%;
        //background: linear-gradient(180deg, #E5FEFF 0%, #FFF 100%);
        background: #fff;
        border-radius: 8px;
        .popup-title {
            display: flex;
            align-items: center;
            padding: 28px 0 0 23px;
            .icon {
                height: 50px;
                width: 50px;
                //background-color: aqua;
                border-radius: 12px;
                margin-right: 12px;
                background: url('../../assets/png/yaojin.jpg') no-repeat;
                background-size: 100% 100%;
            }
            .right-box {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                //height: 70px;
                .title {
                    font-size: 12px;
                    color: #333333;
                    font-weight: 500;
                }
                .desc {
                    font-size: 12px;
                    color: #999999;
                    margin-top: 4px;
                }
            }
        }
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px 0 26px 0;

            .userInfo{
                margin-left: 16px;

                .title{
                    font-size: 16px;
                    color: #333333;
                }

                .desc{
                    margin-top: 8px;
                    font-size: 12px;
                    color: #333333;

                    span{
                        color: #FC4162;
                    }
                }
            }

            .content-top {
                display: flex;
                .qrcode {
                    margin-right: 22px;
                    background: #ffffff;
                    padding: 3px;
                    border-radius: 3px;
                }
                .content-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    height: 61px;
                    .content-title {
                        font-size: 12px;
                        font-weight: 500;
                        color: rgb(255, 255, 255);
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        span {
                            color: rgb(255, 255, 255);
                            font-weight: bold;
                            font-size: 20px;
                        }
                        font {
                            display: inline-block;
                            width: 170px;
                            word-wrap: break-word;
                        }
                    }
                    .code {
                        font-size: 36px;
                        font-weight: 500;
                        color: rgba(255, 255, 255, 0.7);
                    }
                }
            }
            .content-bottom {
                font-size: 11px;
                color: rgba(255, 255, 255, 0.7);
                //margin: 12px auto;
                margin-top: 21px;
                text-align: center;
                font-size: 11px;
                letter-spacing: 0.22px;
            }
        }
    }
    .btn-bottom {
        display: flex;
        margin: 20px 0 0 0;
        justify-content: center;
        align-items: center;
        // padding-bottom: 22px;
        .save {
            width: 124px;
            height: 38px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: rgba(176, 159, 217, 1);
            font-weight: 900;
            box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
            border-radius: 19px;
            //background: url('@/assets/images/png/btn/saveImg.png') no-repeat;
            //background-size: 100% 100%;
            //margin: 0 0 0 15px;
        }
        .copy {
            width: 124px;
            height: 38px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: rgba(176, 159, 217, 1);
            font-weight: 900;
            box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
            border-radius: 19px;
            margin-right: 8px;
            //background: url('@/assets/images/png/btn/copybtn.png') no-repeat;
            //background-size: 100% 100%;
        }
    }
    :deep(.van-popup) {
        border-radius: 8px;
    }
    :deep(.van-overlay) {
        background-color: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(5px);
    }
    .cover {
        width: 256px;
        height: 150px;
        //border-radius: 10px;
        overflow: hidden;
        margin: 15px auto 16px;
        position: relative;

        .coverMasking {
            width: 260px;
            height: 150px;
            background: rgba(0, 0, 0, 0.4);
            position: absolute;
            top: 0;
        }

        .play {
            width: 23px;
            height: 25.3px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1999;
        }
    }
}
</style>
