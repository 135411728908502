import router from './router';
import store from './store';
import { getSessionItem } from '@/utils/longStorage';
//全局路由导航
router.beforeEach((to, from, next) => {
  const appConfigStr = getSessionItem('appConfig');
  const baseUrl = getSessionItem('baseUrl');
  if ((!appConfigStr || appConfigStr.length == 0 || !baseUrl || baseUrl.length == 0) && to.path != '/') {
    router.push({
      path: '/',
    });
    return;
  }
  //缓存组件
  let keepAliveMap = new Map();
  keepAliveMap.set('movies', 'movies');
  keepAliveMap.set('uploadGraphics', 'uploadGraphics');
  keepAliveMap.set('uploadVIdeo', 'uploadVIdeo');
  keepAliveMap.set('ComFoucus', 'ComFoucus');
  keepAliveMap.set('Main', 'Main');
  keepAliveMap.set('search', 'search');
  keepAliveMap.set('announcement', 'announcement');
  keepAliveMap.set('SearchDetails', 'SearchDetails');
  keepAliveMap.set('tags', 'tags');
  keepAliveMap.set('citys', 'citys');
  keepAliveMap.set('moviesMore', 'moviesMore');
  // keepAliveMap.set('HistoryRecord', 'HistoryRecord')
  keepAliveMap.set('addUser', 'addUser');
  keepAliveMap.set('wishList', 'wishList');
  keepAliveMap.set('myCollection', 'myCollection');
  keepAliveMap.set('myPurchases', 'myPurchases');
  keepAliveMap.set('PicturePreview', 'PicturePreview');
  keepAliveMap.set('worksManages', 'worksManages');
  keepAliveMap.set('VideoModule', 'VideoModule');
  keepAliveMap.set('VideoVaultPage', 'VideoVaultPage');
  keepAliveMap.set('Original', 'Original');
  keepAliveMap.set('PlayletModule', 'PlayletModule');
  keepAliveMap.set('ShortVideo', 'ShortVideo');
  keepAliveMap.set('Playlet', 'Playlet');
  keepAliveMap.set('BingeWatch', 'BingeWatch');
  // keepAliveMap.set('TopicPage', 'TopicPage')

  keepAliveMap.set('Home', 'Home');
  keepAliveMap.set('tagsDetailPage', 'tagsDetailPage');
  keepAliveMap.set('purchased', 'purchased');
  keepAliveMap.set('historyRecord', 'historyRecord');
  keepAliveMap.set('creationCenter', 'creationCenter');
  keepAliveMap.set('categoriesPage', 'categoriesPage');
  // keepAliveMap.set('UserHomePage', 'UserHomePage')
  keepAliveMap.set('publishImg', 'publishImg');
  keepAliveMap.set('publishVideo', 'publishVideo');
  // keepAliveMap.set('tagList', 'tagList')
  keepAliveMap.set('HotRankingPage', 'HotRankingPage');
  keepAliveMap.set('HotTopic', 'HotTopic');
  keepAliveMap.set('TopicDetails', 'TopicDetails');
  keepAliveMap.set('aiUndress2', 'aiUndress2');
  keepAliveMap.set('NovelDetailsPage', 'NovelDetailsPage');
  keepAliveMap.set('searchResultPage', 'searchResultPage');
  keepAliveMap.set('AudioBookDetails', 'AudioBookDetails');
  keepAliveMap.set('tagMore', 'tagMore');
  keepAliveMap.set('AiGirlFriend', 'AiGirlFriend');

  //小说详情
  if (
    (from.path === '/home' ||
      from.path === '/topicMore' ||
      from.path === '/tagsDetailPage' ||
      from.path === '/searchResultPage' ||
      from.path === '/novelDetailsPage' ||
      from.path === '/audioBookDetails' ||
      from.path === '/hotRankingPage' ||
      from.path === '/tagMore' ||
      from.path === '/topicPage' ||
      from.path === '/original') &&
    (to.path === '/novelDetailsPage' || to.path === '/audioBookDetails')
  ) {
    keepAliveMap.delete('NovelDetailsPage');
    keepAliveMap.delete('AudioBookDetails');
  }
  if (to.path === '/hotRankingPage' && from.path === '/home') {
    keepAliveMap.delete('HotRankingPage');
  }
  //发布图文页
  if (from.path == '/uploadGraphics' && to.path != '/topicMore' && to.path != '/city' && to.path != '/releaseRules') {
    keepAliveMap.delete('uploadGraphics');
  }
  //发布视频页
  if (from.path == '/uploadVIdeo' && to.path != '/topicMore' && to.path != '/city' && to.path != '/releaseRules') {
    keepAliveMap.delete('uploadVIdeo');
  }
  // 游戏
  if (to.path == '/game' || to.path == '/mine' || to.path == '/bookshelf' || from.path == '/mine') {
    keepAliveMap.delete('Main');
  }
  //搜索页面
  if (
    from.path == '/search' &&
    to.path != '/search/details/movies' &&
    to.path != '/search/details/video' &&
    to.path != '/search/details/graphices' &&
    to.path != '/search/details/user' &&
    to.path != '/search/details/topic' &&
    to.path != '/communityVideo' &&
    to.path != '/userHomePage'
  ) {
    keepAliveMap.delete('search');
  }
  //搜索详情页
  if (from.path === '/searchPage' && to.path === '/searchResultPage') {
    keepAliveMap.delete('searchResultPage');
  }
  // 搜索标签更多
  if (
    (from.path == '/home' ||
      from.path == '/videoDetailsPage' ||
      from.path == '/novelDetailsPage' ||
      from.path == '/topicPage' ||
      from.path == '/historyRecord' ||
      from.path == '/searchPage' ||
      from.path == '/tagsDetailPage' ||
      from.path == '/hotRankingPage' ||
      from.path == '/categoriesPage' ||
      from.path == '/portrayPage' ||
      from.path === '/audioBookDetails' ||
      from.path === '/hotRankingPage' ||
      from.path === '/searchResultPage') &&
    to.path === '/tagMore'
  ) {
    keepAliveMap.delete('tagMore');
  }

  //片库
  if (from.path === '/videoModule' && to.path == '/videoVaultPage') {
    keepAliveMap.delete('VideoVaultPage');
  }

  //片库
  if (from.path === '/videoModule' && to.path == '/playletModule') {
    keepAliveMap.delete('PlayletModule');
  }

  //标签详情页
  if (
    (from.path === '/tags/video' || from.path === '/tags/graphics' || '/tags/movies') &&
    to.path != '/tags/video' &&
    to.path != '/tags/graphics' &&
    to.path != '/tags/movies' &&
    to.path != '/communityVideo' &&
    to.path != '/picturePreview' &&
    to.path != '/horizontalVideo' &&
    to.path != '/userHomePage'
  ) {
    keepAliveMap.delete('tags');
  }
  //标签页跳标签页
  if (
    (from.path == '/tags/video' || from.path == '/tags/graphics') &&
    (to.query.fromPath == '/tags/graphics' || to.query.fromPath == '/tags/video') &&
    from.path != '/communityVideo' &&
    from.path != '/picturePreview'
  ) {
    keepAliveMap.delete('tags');
  }
  if (
    (to.path == '/tags/video' || to.path == '/tags/movies') &&
    from.path == '/horizontalVideo' &&
    !from.query.fromPath
  ) {
    keepAliveMap.delete('tags');
  }
  //标签页跳播放页调标签页
  if ((to.path == '/tags/video' || to.path == '/tags/movies') && from.query.fromPath == 'tags') {
    let tagId = getSessionItem('tagId');
    if (tagId) {
      keepAliveMap.delete('tags');
    }
  }
  //城市详情页
  if (
    (from.path === '/citys/graphics' || from.path === '/citys/video') &&
    to.path != '/citys/graphics' &&
    to.path != '/citys/video' &&
    to.path != '/communityVideo' &&
    to.path != '/picturePreview' &&
    to.path != '/userHomePage'
  ) {
    keepAliveMap.delete('citys');
  }
  //影视详情页
  if (from.path == '/movies/more' && to.path != '/horizontalVideo') {
    keepAliveMap.delete('moviesMore');
  }
  //公告详情
  if (from.path == '/announcement' && to.path != '/announDetails') {
    keepAliveMap.delete('announcement');
  }

  if ((from.path == '/community' || from.path == '/hotTopic') && to.path == '/topicDetails') {
    keepAliveMap.delete('TopicDetails');
  }

  //历史详情
  if (from.path == '/historyMovies' && to.path != '/horizontalVideo' && to.path != '/historyVideo') {
    keepAliveMap.delete('HistoryRecord');
  }
  if (from.path == '/historyVideo' && to.path != '/communityVideo' && to.path != '/historyMovies') {
    keepAliveMap.delete('HistoryRecord');
  }
  // 添加用户
  if (from.path == '/addUser' && to.path != '/userHomePage') {
    keepAliveMap.delete('addUser');
  }
  // 心愿工单
  if (from.path == '/wishList' && to.path != '/questionDetails') {
    keepAliveMap.delete('wishList');
  }
  // 我的收藏
  if (to.path == '/mine') {
    keepAliveMap.delete('myCollection');
  }
  // 我的购买
  if (to.path == '/mine') {
    keepAliveMap.delete('myPurchases');
  }
  // 用户主页
  // if (to.path != '/communityVideo' && to.path != "/picturePreview" && to.path != "/postDetails" && to.path != "/tags/video" && to.path != '/userHomePage' || (to.path == '/userHomePage' && to.query.from)) {
  //     keepAliveMap.delete('UserHomePage');
  // }
  //图片预览
  if (from.path == '/picturePreview' && to.path != '/memberCentre' && to.path != '/sharePromote') {
    keepAliveMap.delete('PicturePreview');
  }
  //作品管理
  if (from.path == '/worksManage' && to.path != '/postDetails' && to.path != '/communityVideo') {
    keepAliveMap.delete('worksManages');
  }

  //图文上传
  if (from.path == '/publishImg' && to.path == '/creationCenter') {
    keepAliveMap.delete('publishImg');
  }

  //图文上传
  if (from.path == '/publishVideo' && to.path == '/creationCenter') {
    keepAliveMap.delete('publishVideo');
  }
  // 专栏更多
  if ((from.path == '/topicPage' && to.path == '/home') || (from.path == '/topicPage' && to.path == '/original')) {
    keepAliveMap.delete('TopicPage');
  }
  //创作中心
  if (from.path == '/creationCenter' && to.path == '/mine') {
    keepAliveMap.delete('creationCenter');
  }
  // console.log(from.path == '/userHomePage' && to.path == '/community')
  //用户主页
  if (
    (from.path == '/userHomePage' && to.path == '/mine') ||
    (from.path == '/community' && to.path == '/userHomePage') ||
    (from.path == '/focusList' && to.path == '/userHomepage')
  ) {
    keepAliveMap.delete('UserHomePage');
  }
  //热门分类
  if (from.path == '/categoriesPage' && to.path == '/home') {
    keepAliveMap.delete('categoriesPage');
  }
  //历史记录
  if (from.path == '/historyRecord' && to.path == '/mine') {
    keepAliveMap.delete('historyRecord');
  }
  //已购
  if (from.path == '/purchased' && to.path == '/mine') {
    keepAliveMap.delete('purchased');
  }
  //搜索结果页
  if (from.path == '/tagsDetailPage' && to.path == '/searchPage') {
    keepAliveMap.delete('tagsDetailPage');
  }
  //视频标签列表
  // if ((from.path == '/tagList' && to.path == '/home') || (from.path == '/tagList' && to.path == '/videoDetailsPage') || (from.path == '/tagList' && to.path == '/novelDetailsPage') || (from.path == '/tagList' && to.path == '/topicPage')) {
  // console.log(to.query.fromTagList)
  if (
    from.path == '/tagList' &&
    (to.path == '/home' ||
      to.path == '/videoDetailsPage' ||
      to.path == '/novelDetailsPage' ||
      to.path == '/topicPage' ||
      to.path == '/historyRecord' ||
      to.path == '/searchPage' ||
      to.path == '/tagsDetailPage' ||
      to.path == '/hotRankingPage' ||
      to.path == '/categoriesPage' ||
      to.path == '/portrayPage') &&
    to.query.fromTagList != 'true'
  ) {
    keepAliveMap.delete('tagList');
  }
  //AI脱衣
  if (['/home', '/videoModule', '/mine'].includes(to.path)) {
    keepAliveMap.delete('aiUndress2');
  }

  if (to.path === '/mine') {
    keepAliveMap.delete('AiGirlFriend');
  }
  let keepAliveList = [...keepAliveMap.values()];
  // console.log(keepAliveList, "------------")
  store.commit('app/SET_KEEP', keepAliveList);

  if (to.matched.length === 0) {
    //如果未能匹配到路由 则跳转到首页
    next('/');
  } else {
    next();
  }
});
