import axios from '@/utils/request';

/************************* 关注列表 *************************/
// 查询关注列表
export function queryFollowList(data) {
  return axios.get(`follow/list`, { params: data });
}
// 查询关注话题列表
export function queryHotspotList(data) {
  return axios.get(`mine/collect/infoList`, { params: data });
}

/************************* 粉丝列表 *************************/
// 查询粉丝列表
export function queryFanList(data) {
  return axios.get(`follow/fans/list`, { params: data });
}

/************************* 领取兑换 *************************/
// 兑换
export function exchange(data) {
  return axios.post(`code/exchange`, data);
}
export function queryExchangeLog(data) {
  return axios.post(`code/exchange/log`, data);
}

/************************* 用户主页 *************************/
// 查询作品列表
export function queryWorkList(data) {
  return axios.get(`mine/collection`, { params: data });
}
export function queryLikeList(data) {
  return axios.get(`mine/like`, { params: data });
}
export function queryuserLikeList(data) {
  return axios.get(`mine/like/list`, { params: data });
}

// 打赏
export function reward(data) {
  return axios.post(`mine/reward`, data);
}
// 查询评论列表
export function queryComment(data) {
  return axios.get(`comment/list`, { params: data });
}

// 查询二级评论列表
export function getSecCommentList(data) {
  return axios.get(`/comment/info`, { params: data });
}

// 发送评论
export function sendComment(data) {
  return axios.post(`comment/send`, data);
}

// 视频发布评论
export function sendV2Comment(data) {
  return axios.post(`comment/send`, data);
}

// 评论点赞
export function thumbsUpComment(data) {
  return axios.post(`thumbsUp`, data);
}
// 评论取消点赞
export function thumbsDownComment(data) {
  return axios.post(`thumbsDown`, data);
}
// 查询作品列表
export function queryMineCollection(data) {
  return axios.get(`mine/collection`, { params: data });
}
// 查询 1-应用中心 2-官方社群 3-商务合作
export function qyeryOfficial(data) {
  return axios.get(`official/list`, { params: data });
}
// 查询账单明细
export function queryBill(data) {
  return axios.get(`mine/rchg/order`, { params: data });
}
// 查询消费记录
export function queryZqzhangdan(data) {
  return axios.get(`mine/zqzhangdan`, { params: data });
}
// 查询推广记录
export function queryPromotionRecord(data) {
  return axios.post(`userinvite/userlist`, data);
}
// 查询作品列表
export function queryCollection(data) {
  return axios.get(`/mine/collection`, { params: data });
}
// 删除作品
export function delVid(data) {
  return axios.post(`/vid/del`, data);
}
// 我的消息
export function querMyMsg(data) {
  return axios.get(`/msg/dynamic/list`, { params: data });
}
// 用户背包
export function queryBackpack(data) {
  return axios.get(`/backpack`, { params: data });
}

/**
 * @description 查询用户未读消息
 */
export function queryMsgHasNew() {
  return axios.get('/mine/msgHasNew');
}

/**
 * @description 查询用户未读消息列表
 * @param data
 */
export function queryMsgList(data) {
  return axios.get(`/mine/msg`, { params: data });
}

/*********************** 会员中心 ******************/
export function queryVipList(data) {
  return axios.get(`vip/product`, { params: data });
}
// 获取新手卡时间
export function queryTiroCountdown() {
  return axios.get(`vip/tiroCountdown`);
}
// 金币兑换
export function goldExchange(data) {
  return axios.post(`product/buy`, data);
}
// 充值
export function getPay(data) {
  return axios.post(`mine/topay`, data);
}

/*********************** 金币列表 ******************/
export function queryCurrencys(data) {
  return axios.get(`mine/currencys`, { params: data });
}

/*********************** 收益明细 ******************/
export function queryIncomes(data) {
  return axios.get(`mine/iIncomes`, { params: data });
}

/*********************** 提现 ******************/
// 查询提现配置
export function queryWithdrawCfg(data) {
  return axios.get(`withdraw/cfg`, { params: data });
}
// 查询提现明细
export function queryWithdrawDetails(data) {
  return axios.get(`withdraw/order`, { params: data });
}
// 提现
export function withdraw(data) {
  return axios.post(`withdraw`, data);
}
// 检验银行卡号
export function checkBankCard(data) {
  return axios.get(`https://ccdcapi.alipay.com/validateAndCacheCardInfo.json`, {
    params: data,
  });
}
// 查询提现银行卡
export function queryBankCards(data) {
  return axios.get(`mine/txnact/yh/get`, {
    params: data,
  });
}
// 绑定银行卡
export function bindBankCard(data) {
  return axios.post(`mine/txnact/yh/add`, data);
}
// 绑定银行卡
export function delBankCard(data) {
  return axios.delete(`mine/txnact/del`, { data: data });
}

/*********************** AI脱衣 ******************/

//获取AI脱衣记录列表
export function getAiRecord(data) {
  return axios.get('ai/undress/list', { params: data });
}

// 上传图片
export function uploadFile(data) {
  return axios({
    url: 'vid/uploadStatic',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}
// ai列表----------------------------------------
/**
 * @description   ai模版列表
 */
export function getAiMod() {
  return axios.get('/ai/mod/list');
}
// ai脱衣模块--------------------------------------------
/**
 * @description   ai脱衣
 */
export function generateImg(data) {
  return axios.post('/ai/undress/generate', data);
}
/**
 * @description   ai脱衣隐藏
 */
export function delelteUndress(data) {
  return axios.post('/ai/undress/hide', data);
}
/**
 * @description   AI脱衣生成记录列表
 */
export function getAiUndressRecord(data) {
  return axios.get(`/ai/undress/list`, { params: data });
}

// ai视频换脸模块-------------------------------------------
/**
 * @description   ai换脸隐藏
 */
export function delelteChangeVideoFace(data) {
  return axios.post('/ai/changeface/hide', data);
}
/**
 * @description   ai换脸
 */
export function generateChangeVideo(data) {
  return axios.post('/ai/changeface/generate', data);
}
/**
 * @description   AI视频换脸生成记录列表
 */
export function getAiChangeFaceVideoRecord(data) {
  return axios.get('/ai/changeface/list', { params: data });
}

// ai图片换脸模块-------------------------------------------

/**
 * @description   ai换脸
 */
export function generateChangeImg(data) {
  return axios.post('/ai/img/generate', data);
}
/**
 * @description   AI视频换脸生成记录列表
 */
export function getAiChangeFaceImgRecord(data) {
  return axios.get('/ai/img/list', { params: data });
}
/**
 * @description   ai换脸隐藏
 */
export function delelteChangeImgFace(data) {
  return axios.post('/ai/img/hide', data);
}

/**
 * @description   ai换脸模版
 */
export function getChangeFaceMod(data) {
  return axios.get('/ai/changeface/mod/list', data);
}

/**
 * @description   用户余额查询
 */
export function getAimateGetBalance(data) {
  return axios.get('/aimate/getBalance', data);
}

/**
 * @description   登陆
 */
export function getAimateLogin(data) {
  return axios.get('/aimate/login', data);
}

/**
 * @description   AI女友货币列表
 */
export function getAimateCurrencys(data) {
  return axios.get('/aimate/currencys', data);
}

/**
 * @description   AI女友积分兑换
 */
export function postAimateExchange(data) {
  return axios.post('/aimate/exchange', data);
}
