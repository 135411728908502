import axios from "@/utils/request";


//获取影视跑马灯
export function getAnnounce(data) {
    return axios.get(`modules/announce`, {params: data});
}

//获取娱乐数据--广告列表
export function getRecreatio() {
    return axios.get(`/recreation/list`,{});
}

//获取娱乐数据--广告点击上报
export function upperRecreatio(data) {
    return axios.post(`/recreation/click`,data);
}

