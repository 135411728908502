import {setSessionItem} from "@/utils/longStorage";
import Vue from 'vue';

const state = {
  maxEpisodes: JSON.parse(sessionStorage.getItem("maxEpisodes")) || {},  // 存储每本小说的 maxEpisode
  photoData: {},  // 存储写真数据
  watchStatus: JSON.parse(sessionStorage.getItem("watchStatus")) || {}, // 新增状态
}

const mutations = {
  // 设置 maxEpisode
  SET_MAX_EPISODE: (state, { novelId, maxEpisode }) => {
    console.log(novelId, maxEpisode);
    state.maxEpisodes[novelId] = maxEpisode;
    setSessionItem("maxEpisodes", JSON.stringify(state.maxEpisodes));
  },
  SET_PHOTO_DATA(state, payload) {
    state.photoData = payload;
  },
  SET_WATCH_STATUS: (state, { id, index, status }) => {
    if (!state.watchStatus[id]) {
      Vue.set(state.watchStatus, id, {});
    }
    Vue.set(state.watchStatus[id], index, status);
    setSessionItem("watchStatus", JSON.stringify(state.watchStatus));
  },
}

const actions = {
  setMaxEpisode({ commit }, { novelId, maxEpisode }) {
    commit('SET_MAX_EPISODE', { novelId, maxEpisode });
  },
  savePhotoData({ commit }, data) {
    commit('SET_PHOTO_DATA', data);
  },
  setWatchStatus: ({ commit }, data) => {
    commit('SET_WATCH_STATUS', data);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
