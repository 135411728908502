<template>
  <div class="splash">
    <div class="countdown" v-if="isStateTime" @click="goApp">
      <span v-if="countdown !== 0">{{ countdown }}</span>
      <span v-else class="closetext">进入</span>
      <!-- <svg-icon v-else iconClass="close" class="close"></svg-icon> -->
    </div>
    <transition name="shareShow">
      <LazyImg
        :src="ATP_LaunchList[0].cover"
        :isSplash="true"
        v-if="ATP_LaunchList.length > 0"
        @click.native="goAdv(ATP_LaunchList[0])"
      />
    </transition>
    <div class="selectLineBox" v-if="loading">
      <van-loading color="#1989fa" size="30" />
      <div class="selectLine">选线中...</div>
    </div>
  </div>
</template>
<script>
// import ping from "@/utils/ping";
import { queryApi,queryAllModulesList } from "@/api/app";
// import store from "@/store";
import {
  cleanAllSession,
  getLocalItem,
  removeSession,
  setLocalItem,
  setSessionItem,
} from "@/utils/longStorage";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
import { mapGetters } from "vuex";
import { getDevType } from "@/utils";
import { userLogin } from "@/api/user";
import { Dialog, Toast } from "vant";
import axios from "axios";
import store from "@/store/index.js";

export default {
  name: "Splash",
  components: {
    LazyImg: () => import("@/components/LazyImg/index"),
  },
  data() {
    return {
      timer: null,
      countdown: 5, //广告倒计时
      retryNum: 3, //获取token失败重试次数
      ATP_LaunchList: [], //启动页广告
      isStateTime: false, //是否开始显示倒计时
      isResLock: false, // 请求结果锁
      resResult: null, // 响应结果
      pingCheckCount: 0, // 请求的ping失败次数
      loading: true, // 选线loading
    };
  },
  computed: {
    ...mapGetters({
      UID: "UID",
    }),
  },
  created() {
    try {
      removeSession("advSudokuPop");
      removeSession("announcementTextPop");
      removeSession("advPicturePop");
      this.init();
    } catch (error) {
      Dialog.alert({
        title: "提示",
        message: "选线失败",
        confirmButtonText: "重试",
        theme: "round-button",
      }).then(() => {
        cleanAllSession();
        this.init();
      });
      return;
    }
  },
  methods: {
    goAdv(item) {
      jumpAdv(item);
    },
    //初始化数据
    async init() {
      const baseUrls = process.env.VUE_APP_BASE_HOSTS || "[]";
      const hosts = JSON.parse(baseUrls);
      this.resResult = new Array(hosts.length);
      hosts.forEach((url, index) => {
        axios({
          method: "GET",
          url: url + process.env.VUE_APP_BASE_PATH + "ping/check",
          timeout: 1000 * 10, // 10秒超时
        })
          .then(async () => {
            if (!this.isResLock) {
              this.isResLock = true;
              this.resResult[index] = true;
              // let baseUrl = line + process.env.VUE_APP_BASE_PATH
              sessionStorage.setItem(
                "baseUrl",
                url + process.env.VUE_APP_BASE_PATH
              );
              store.commit(
                "app/SET_BASEURL",
                url + process.env.VUE_APP_BASE_PATH
              );
              //获取用户信息
              let isUserOk = await this.getUserInfo();
              if (!isUserOk) {
                Dialog.alert({
                  title: "提示",
                  message: "获取用户信息失败",
                  confirmButtonText: "重试",
                  theme: "round-button",
                }).then(() => {
                  cleanAllSession();
                  this.init();
                });
                return;
              }
              //获取配置信息
              let isConfigOk = await this.getConfigData();
              if (!isConfigOk) {
                Dialog.alert({
                  title: "提示",
                  message: "获取全局配置信息失败",
                  confirmButtonText: "重试",
                  theme: "round-button",
                }).then(() => {
                  cleanAllSession();
                  this.init();
                });
                return;
              }
              await this.getAllModulesList();
              this.adControl();
              this.$store.dispatch("user/getWallet");
              this.$store.dispatch("app/getMarquee");
              let appConfig = this.$store.getters.appConfig;
              let imgDomainList = appConfig?.sourceList?.filter(
                (item) => item.type === "VID"
              );
              if (imgDomainList && imgDomainList.length > 0) {
                this.$store.commit(
                  "video/SET_VIDEOROADLINE",
                  imgDomainList[0]?.domain[0]
                );
              }
            }
          })
          .catch(() => {
            // 只有最后一条域名出错时，判断响应结果不存在true的时候，说明全部不通，即弹出选线失败
            this.pingCheckCount++;
            if (this.pingCheckCount == hosts.length) {
              this.pingCheckCount = 0;
              let index = this.resResult.indexOf(true);
              if (index == -1) {
                if (this.checkLineErrorNum == 1) {
                  this.isCheckError = true;
                  return;
                }
                Dialog.alert({
                  title: "提示",
                  message: "选线失败",
                  confirmButtonText: "重试",
                  theme: "round-button",
                }).then(() => {
                  cleanAllSession();
                  this.init();
                });
              }
            }
          });
      });
      // let isOk = await ping();
      // //选线失败
      // if (!isOk) {
      // Dialog.alert({
      //   title: "提示",
      //   message: "选线失败",
      //   confirmButtonText: "重试",
      //   theme: "round-button",
      // }).then(() => {
      //   cleanAllSession();
      //   this.init();
      // });
      //   return;
      // }
      // //获取用户信息
      // let isUserOk = await this.getUserInfo();
      // if (!isUserOk) {
      //   Dialog.alert({
      //     title: "提示",
      //     message: "获取用户信息失败",
      //     confirmButtonText: "重试",
      //     theme: "round-button",
      //   }).then(() => {
      //     cleanAllSession();
      //     this.init();
      //   });
      //   return;
      // }
      // //获取配置信息
      // let isConfigOk = await this.getConfigData();
      // if (!isConfigOk) {
      //   Dialog.alert({
      //     title: "提示",
      //     message: "获取全局配置信息失败",
      //     confirmButtonText: "重试",
      //     theme: "round-button",
      //   }).then(() => {
      //     cleanAllSession();
      //     this.init();
      //   });
      //   return;
      // }
      // this.adControl();
      // this.$store.dispatch("user/getWallet");
      // this.$store.dispatch("app/getMarquee");
      // let appConfig = this.$store.getters.appConfig;
      // let imgDomainList = appConfig?.sourceList?.filter(
      //   (item) => item.type === "VID"
      // );
      // if (imgDomainList && imgDomainList.length > 0) {
      //   this.$store.commit(
      //     "video/SET_VIDEOROADLINE",
      //     imgDomainList[0]?.domain[0]
      //   );
      // }
    },
    //开机广告
    adControl() {
      this.loading = false;
      this.ATP_LaunchList = getAdItem(AdType.ATP_Launch);
      this.countdownStrat();
      this.isStateTime = true;
    },
    //启动倒计时
    countdownStrat() {
      this.timer = setInterval(() => {
        if (this.countdown <= 0) {
          clearInterval(this.timer);
        } else {
          this.countdown--;
        }
      }, 1000);
    },
    //获取全局配置
    async getConfigData() {
      let ret = await this.$Api(queryApi);
      if (ret.code === 200) {
        const appConfig = ret.data;
        setSessionItem("appConfig", JSON.stringify(appConfig));
        this.$store.commit("app/SET_CONFIG_DATA", appConfig);
        return true;
      }
      return false;
    },
    //获取全部模块配置
    async getAllModulesList() {
      let ret = await this.$Api(queryAllModulesList);
      if (ret.code === 200) {
        const modulesInfo = ret.data;
        setSessionItem("modulesInfo", JSON.stringify(modulesInfo));
        this.$store.commit("app/SET_MODULESINFO_DATA", modulesInfo);
      }
    },
    async getUserInfo() {
      const token = getLocalItem("token");
      if (token) {
        this.$store.dispatch("user/getUserInfo");
        return true;
      }
      if (!this.UID) {
        this.$store.dispatch("app/GET_UID");
      }
      const req = {
        devID: this.UID,
        sysType: getDevType(),
        cutInfos:
          Object.keys(this.$route.query).length > 0
            ? JSON.stringify(this.$route.query)
            : undefined,
      };
      try {
        let res = await this.$Api(userLogin, req);
        if (res.code === 200) {
          setSessionItem("userInfo", JSON.stringify(res.data));
          setLocalItem("token", res.data.token);
          return true;
        }
        //如果不等于 200 进行重试
        this.retryNum--;
        if (!token && this.retryNum >= 0) {
          await this.getUserInfo();
        }
        //如果不等于 200 进行重试
      } catch (e) {
        this.retryNum--;
        if (!token && this.retryNum >= 0) {
          await this.getUserInfo();
        }
      }
    },
    goApp() {
      if (this.countdown != 0) return;
      this.$router.replace({ path: "/home" });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style lang="scss" scoped>
.splash {
  width: 100%;
  height: 100%;
  background: url("../../assets/png/ad.png") center no-repeat;
  background-size: 100% 100%;
  object-fit: contain;
  position: relative;

  .countdown {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 999;
    width: 62px;
    height: 30px;
    // font-size: 16px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px;
    .closetext {
      font-size: 16px;
    }
  }

  .close {
    font-size: 30px;
  }
  .selectLineBox {
    height: 120px;
    width: 150px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .selectLine {
      margin-top: 20px;
      font-size: 16px;
    }
  }
}
</style>
