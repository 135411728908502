import {Api} from "@/utils";
import {queryTiroCountdown, queryUserInfo, queryWallet} from "@/api/user";
import {getSessionItem, setSessionItem} from "@/utils/longStorage";
import { queryMsgHasNew } from "@/api/mine";

const state = {
    userInfo: getSessionItem("userInfo") || {}, // 用户信息
    walletInfo: {}, // 钱包信息
    areaCode: "+86", // 区号
    rewarDate: {
        show: false,   //是否打开打赏
        info: {}    //视频信息
    },
    // 评论弹窗
    commentPopDate: {
        show: false,
        info: {}
    },
    msgHasNew: {},
}
const mutations = {
    /*******************************/
    // 用户信息
    SET_USERINFO: (state, info) => {
        state.userInfo = info;
    },
    // 钱包信息
    SET_WALLET: (state, walletInfo) => {
        state.walletInfo = walletInfo;
    },
    // 设置区号
    SET_AREACODE: (state, areaCode) => {
        state.areaCode = areaCode;
    },
    // 打赏弹窗信息
    SET_REWAE(state, data) {
        state.rewarDate = data;
    },
    // 评论弹窗
    SET_COMMENTPOP(state, data) {
        state.commentPopDate = data;
    },
    // 我的消息
    UPDATE_MSG_HAS_NEW(state, data) {
        state.msgHasNew = data;
    }
}

const actions = {
    async getUserInfo({commit}) {
        let res = await Api(queryUserInfo);
        if (res.code === 200) {
            setSessionItem("userInfo", JSON.stringify(res.data));
            commit("SET_USERINFO", res.data);
        }
    },
    async getWallet({commit}) {
        let res = await Api(queryWallet);
        if (res.code === 200) {
            commit("SET_WALLET", res.data);
        }
    },
    async updateMsgHasNew({ commit }) {
        let res = await Api(queryMsgHasNew);
        if (res.code === 200) {
            commit('UPDATE_MSG_HAS_NEW', res.data);
        }
    }


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
