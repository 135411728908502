import {getMoudles} from "@/api/movies";
import {Api} from "@/utils";
import {getSessionItem, setSessionItem} from "@/utils/longStorage";

const state = {
    videoTimes: getSessionItem("videoTimes") ? JSON.parse(getSessionItem("videoTimes")) : [],
    buyStatus: null,
}

const mutations = {
    ADD_VIDEO_TIME(state, { id, time }) {
        const index = state.videoTimes.findIndex(video => video.id === id);
        if (index !== -1) {
            state.videoTimes[index].time = time;
        } else {
            state.videoTimes.push({ id, time });
        }
        if (state.videoTimes.length > 5) {
            state.videoTimes.shift(); // 删除数组的最后一个元素
        }
        setSessionItem("videoTimes", JSON.stringify(state.videoTimes));
        // state.videoTimes[id] = time;
        console.log(state.videoTimes, '111111111');
    },
    SET_BUY_STATUS(state, value) {
        state.buyStatus = value;
    }
}

const actions = {
    updateVideoTime({ commit }, payload) {
        commit('ADD_VIDEO_TIME', payload);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
