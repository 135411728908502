import store from '@/store';
import { adClickReport, dataAdd } from '@/api/app';
import { Api, queryHistoryId } from '@/utils/index';
import { getLocalItem, getSessionItem, setSessionItem } from '@/utils/longStorage';
import route from '@/router/index.js';
import getters from '@/store/getters';

export const AdType = {
  ATP_Launch: 0, /// 启动页
  COMMUNITY_RECOMMEND: 1, //社区推荐
  MOVIES_DOMESTIC: 2, //影视
  COMMUNITY_DETAILS: 3, ///社区列表
  COMMUNITY_POST: 4, ///圈子
  MESSAGE_MODULE: 5, ///消息模块
  CREATION_CENTER: 6, ///创作中心
  VIDEO_ADV: 7, ///视频广告
  FREE_VIDEO: 8, ///免费专区
  COLUMN_ADV: 9, ///专栏广告
  TIPIC_MORE: 10, // 首页专题-更多
  COMMUNITY_ADV: 11, //圈子广告
  CLASSIFICARION: 12, //热门分类
  TIPIC_MORE_VERTICLE: 13, //首页专题-更多横版
  CLASSIFICARION_VERTICLE: 14, //热门分类横版
  FLOATING_ADV: 15, //首页浮动广告
  HOMEADV: 28, //首页广告
  VIDEODETAILROWADV: 29, //视频详情页广告
  VIDEOHOMEADV: 30, //视频首页广告
  COMMUNITYADV: 31, //社区首页广告
  NINEGRID_SWIPER: 301, //九宮格輪播廣告
  RECGAME: 201, // 娱乐游戏广告
  RECAPP: 200, // 娱乐游戏广告
  RECYP: 202, // 娱乐约炮广告
  RECQP: 203, // 娱乐棋牌广告
  RECZB: 204, // 娱乐直播广告
};
export const MarqueeType = {
  community: 1, //社区
  movie: 2, //影视
};

/**
 * 获取广告
 * @param {*} type  广告类型
 * @returns   广告数据
 */
export function getAdItem(type) {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (
    type == undefined ||
    !appConfig ||
    !appConfig.ads ||
    !appConfig.ads.adsInfoList ||
    appConfig.ads.adsInfoList.length == 0
  )
    return [];
  let advData = appConfig.ads.adsInfoList.filter((item) => {
    if (item.position == type) {
      return item;
    }
  });
  if (!advData || advData.length == 0) return [];
  return advData;
}

/**
 * 获取模块id
 * @param subName
 * @returns {string|null|*}
 */
export function getModuleId(subName) {
  let moduleList = store.state.movies.moduleList;
  if (moduleList.length == 0) {
    return null;
  }
  let list = moduleList.filter((el) => el.subModuleName == subName);
  if (list.length > 0) {
    return list[0].id;
  }
  return '';
}

/**
 * 获取文字公告
 */
export function getAnnouncementText() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.ads || !appConfig.ads.announList || appConfig.ads.announList.length == 0) return [];
  let announList = appConfig.ads.announList.filter((item) => {
    if (item.type == 0) {
      return item;
    }
  });
  if (!announList || announList.length == 0) return [];
  return announList;
}

/**
 * 获取九宫格广告歌
 */
export function getOfficialConfigApps() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.officialConfigApps || appConfig.officialConfigApps.length == 0) return [];
  return appConfig.officialConfigApps;
}

/**
 * 获取楼凤url
 */
export function getLoufengUrl() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.louFengH5) return null;
  return appConfig.louFengH5;
}

export function getLotteryUrl() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.active_2023_url) return null;
  return appConfig.active_2023_url;
}

/**
 * 获取用户信息
 */
export function getUserInfo() {
  let userInfo = store.state.user.userInfo;
  if (typeof userInfo === 'string') {
    userInfo = JSON.parse(store.state.user.userInfo);
  }
  if (!userInfo) return null;
  return userInfo;
}

/**
 * 获取数据埋点接口地址
 */
export function getDataUrl() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.dataBuriedPoint) return null;
  return appConfig.dataBuriedPoint;
}

/**
 * 获取VIP特权
 */
export function getVipProductBenefits() {
  let appConfig = store.state.app.appConfig;
  if (typeof appConfig === 'string') {
    appConfig = JSON.parse(store.state.app.appConfig);
  }
  if (!appConfig || !appConfig.productBenefits || appConfig.productBenefits.length == 0) return [];
  return appConfig.productBenefits;
}

/**
 * 获取跑马灯
 */
export function getMarquee(type) {
  let list = store.getters.marqueeList || [];
  if (list.length === 0) return '';
  let n = list.filter((e) => e.position == type);
  if (!n || n.length == 0) return '';
  var str = '';
  for (let i = 0; i < n.length; i++) {
    if (n.length > 1) {
      str += `${n[i].content}\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`;
    } else {
      str += `${n[i].content}`;
    }
  }
  return str;
}
/**
 * 获取跑马灯
 */
export function getMarqueeData(type) {
  let list = store.getters.marqueeList || [];
  if (list.length === 0) return '';
  let n = list.filter((e) => e.position == type);
  if (!n || n.length == 0) return '';
  return n[0];
}

/**
 * 获取奖章
 */
export function getMedal(types) {
  // console.log(types)
  let newList = [];
  let list = store.getters.appConfig.userAwards || [];
  if (list.length === 0 || types?.length === 0) return newList;
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < types?.length; j++) {
      if (types[j].number == list[i].number) {
        list[i].isExpire = types[j].isExpire;
        newList.push(list[i]);
      }
    }
  }
  // console.log(newList)
  return newList;
}

/**
 * 截取URL参数
 * @param name 需要截取url的参数
 * @returns
 */
function getUrlParam(url, name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)'); //构造一个含有目标参数的正则表达式对象
  var r = url.substr(1).match(reg); //匹配目标参数

  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}

/**
 * 活动跳转
 * @param {*} item
 */
function Activity(url) {
  let murl = 'https://' + url;
  if (murl.indexOf('token') != -1) {
    let token = getLocalItem('token') || '';
    murl = murl.replace(/token=/, `token=${token}`);
  }
  if (murl.indexOf('apihost') != -1) {
    let baseUrl = getSessionItem('baseUrl').replace(/\/api\/h5\//, '');
    murl = murl.replace(/apihost=/, `apihost=${baseUrl}`);
  }
  location = murl;
}

/**
 * 内部跳转
 * @param {*} url  评论内部跳转的url
 */
export function innerJump2(url) {
  let urlIndex = url.indexOf('?');
  let uri = url;
  if (urlIndex != -1) {
    uri = url.substring(0, urlIndex);
  }
  // 金豆充值
  const routeMap = {
    topic_page: '/specialTopic', //专题
    welfare_page: '/welfareCenter', //福利
    hot_ranking_page: '/hotRankingPage', //热榜
    vip_page: '/rechargePage?t=vip', //会员
    coin_page: '/rechargePage?t=gold', //金豆
    SigninPage: '/sign', //签到
    advancePage: '/presale', //预售
  };
  // 跳转动漫详情
  if (uri == 'cartoonDetails') {
    let id = getUrlParam(url.substring(urlIndex), 'id');
    if (!id) return;
    route.push({ path: '/videoDetailsPage', query: { id: id } });
    return;
  }

  // 跳转漫画详情
  if (uri == 'comicsDetails') {
    let id = getUrlParam(url.substring(urlIndex), 'id');
    if (!id) return;
    route.push({ path: '/novelDetailsPage', query: { id: id } });
    return;
  }

  // 帖子详情
  if (uri == 'postDetails') {
    let id = getUrlParam(url.substring(urlIndex), 'id');
    if (!id) return;
    route.push({ path: '/postDetails', query: { videoID: id } });
    return;
  }
  let routePath = routeMap[uri];
  if (routePath) {
    route.push(routePath);
    return;
  }
}
function jumpPage(path) {
  route.push(path);
}

/**
 * 内部跳转
 * @param {*} url  内部跳转的url
 */
export function innerJump(url) {
  let urlIndex = url.indexOf('?');
  let uri = url;
  if (uri == 'member_centre?initIndex=1') {
    route.push({ path: 'rechargePage', query: { t: 'gold' } });
    return;
  }
  if (urlIndex != -1) {
    uri = url.substring(0, urlIndex);
  }
  // 金豆充值
  const routeMap = {
    share_promotion: '/sharePromotion', //分享推广
    personal_card: '/sharePromotion', //分享推广
    member_centre: '/rechargePage?t=vip', //会员中心页面
    topic_page: '/specialTopic', //专题
    welfare_page: '/welfareCenter', //福利
    hot_ranking_page: '/hotRankingPage', //热榜
    vip_page: '/rechargePage?t=vip', //会员中心页面
    wallet_page: '/rechargePage?t=gold', //金币钱包页面
    coin_page: '/rechargePage?t=gold', //金币钱包页面
    aiundress: '/aiUndress', //AI脱衣
    aiUndress: '/aiUndress', //AI脱衣
    quanzi: '/community', //圈子
    originWorks: '/original', //原味
    SigninPage: '/sign', //签到
    filmli_page: '/', //作品分类
    Activity_page: '/lottery', //幸运占星（H5抽奖）
    Ai_page: '/sign', //签到
    filmli_video: '/videoVaultPage', //视频分类
    filmli_acg: '/categoriesPage', //作品分类
    advancePage: '/presale', //预售
  };
  // 跳转亚模块分类详情
  if (uri == 'module') {
    const id = getUrlParam(url.substring(urlIndex), 'id');
    let result = null;
    // const modulesInfo = sessionStorage.getItem("modulesInfo") || {};
    const tabObj = sessionStorage.getItem('modulesInfo') ? JSON.parse(sessionStorage.getItem('modulesInfo')) : {};
    const animation = tabObj?.animation || [];
    const caricature = tabObj?.caricature || [];
    const homePage = tabObj?.homePage || [];
    const novel = tabObj?.novel || [];
    const original = tabObj?.original || [];
    const photo = tabObj?.photo || [];
    const shortPlay = tabObj?.shortPlay || [];
    const sp = tabObj?.shortPlay || [];
    result = animation.findIndex((item) => {
      return item.id == id;
    });
    if (result != -1) {
      store.commit('app/SET_HOMEINDEX', 0);
      store.commit('app/SET_ANIMATIONINDEX', result);
      jumpPage(`/home`);
      return;
    }
    result = caricature.findIndex((item) => {
      return item.id == id;
    });
    if (result != -1) {
      store.commit('app/SET_HOMEINDEX', 1);
      store.commit('app/SET_CARICATUREINDEX', result);
      jumpPage(`/home`);
      return;
    }
    result = novel.findIndex((item) => {
      return item.id == id;
    });

    console.log(result);
    console.log(tabObj);
    console.log(id);

    if (result != -1) {
      store.commit('app/SET_HOMEINDEX', 2);
      store.commit('app/SET_NOVELINDEX', result);
      jumpPage(`/home`);
      return;
    }
    result = homePage.findIndex((item) => {
      return item.id == id;
    });
    if (result != -1) {
      store.commit('app/SET_VIDEOINDEX', result);
      jumpPage(`/videoModule`);
      return;
    }

    result = original.findIndex((item) => {
      return item.id == id;
    });
    if (result != -1) {
      store.commit('app/SET_ORIGINALINDEX', result);
      jumpPage(`/original`);
      return;
    }
    result = photo.findIndex((item) => {
      return item.id == id;
    });
    if (result != -1) {
      store.commit('app/SET_ORIGINALINDEX', result);
      jumpPage(`/community?t=2`);
      return;
    }
    result = shortPlay.findIndex((item) => {
      return item.id == id;
    });
    if (result != -1) {
      jumpPage(`/playletModule/playlet`);
      return;
    }
    result = sp.findIndex((item) => {
      return item.id == id;
    });
    if (result != -1) {
      jumpPage(`/playletModule/shortVideo`);
      return;
    }
    return;
  }
  // 跳转博主
  if (uri == 'userHomePage') {
    let uid = getUrlParam(url.substring(urlIndex), 'uid');
    if (!uid) return;
    route.push({ path: '/userHomePage', query: { id: uid } });
    return;
  }
  // 跳转视频详情
  if (uri == 'horizontalVideo') {
    let id = getUrlParam(url.substring(urlIndex), 'id');
    if (!id) return;
    route.push({ path: '/horizontalVideo', query: { id: id } });
    return;
  }
  // 跳转活动详情
  if (uri == 'activity') {
    let id = getUrlParam(url.substring(urlIndex), 'id');
    if (!id) return;
    route.push({ path: '/welfareDetails', query: { id: id } });
    return;
  }
  // 跳转漫画
  if (uri == 'comicsDetails' || uri == 'comics') {
    let id = getUrlParam(url.substring(urlIndex), 'id');
    if (!id) return;
    route.push({ path: '/novelDetailsPage', query: { kind: 1, id: id } });
    return;
  }
  // 跳转动漫
  if (uri == 'cartoonDetails' || uri == 'video') {
    let id = getUrlParam(url.substring(urlIndex), 'id');
    if (!id) return;
    route.push({
      path: '/videoDetailsPage',
      query: { id: id, isAcg: 1, kind: 0 },
    });
    return;
  }
  // 跳转帖子
  if (uri == 'postDetails') {
    let id = getUrlParam(url.substring(urlIndex), 'id');
    if (!id) return;
    route.push({ path: '/postDetails', query: { videoID: id } });
    return;
  }
  let routePath = routeMap[uri];
  if (routePath) {
    route.push(routePath);
    return;
  }
}

// 转base64
function encodeBase64(str) {
  return Buffer.from(str, 'utf-8').toString('base64');
}

/**
 * 跳转详情
 * @param {*} url
 */
export async function openDetails(list, item, index) {
  if (item.type) {
    jumpAdv(item);
    return;
  }
  let kind = item['kind'];

  if (kind != null) {
    switch (kind) {
      case 0:
      case 2:
      case 3:
        //真人视频
        this.$router.push({
          path: '/videoDetailsPage',
          query: {
            id: item.id,
            isAcg: 1,
            kind: kind,
          },
        });
        break;
      case 6:
        //跳转视频
        this.$router.push({
          path: '/videoDetailsPage',
          query: {
            id: item.id,
            isAcg: kind === 0 ? 1 : 0,
            kind: kind,
          },
        });
        break;
      case 8:
        this.$store.commit('video/SET_SHORT_VIDEO_LIST', list);
        this.$store.commit('video/UPDATE_SHORT_VIDEO_INDEX', index);
        this.$router.push({
          path: '/shortVideoDetails',
          query: {
            isAcg: 1,
          },
        });
        break;
      case 4:
      case 1:
        store.commit('pageRefresh/SET_REFRESHFLAG', {
          name: 'novelDetailsPage',
          flag: false,
        });
        //小说/漫画
        this.$router.push({
          path: '/novelDetailsPage',
          query: {
            id: item.id,
            kind: kind,
            price: item.price,
          },
        });
        break;
      case 5:
        var lists = JSON.stringify(list);
        //图集
        this.$router.push({
          path: '/portrayPage',
          query: {
            id: item.id,
            kind: item.kind,
            list: encodeBase64(lists),
            phoneIndex: index,
          },
        });
        break;
      case 7:
        try {
          // 获取历史记录的最大子集信息
          let historyPostInfo = (await queryHistoryId(item.id)) || 0;
          // 跳转到有声小说详情页
          this.$router.push({
            path: '/audioBookDetails',
            query: {
              id: item.id,
              kind: item.kind,
              price: item.price,
              itemIndex: historyPostInfo.historyIndex || 0,
            },
          });
        } catch (error) {
          console.error(error);
        }
        break;
    }
  } else {
    if (item.newsType === 'COVER') {
      this.$router.push({
        path: '/galleryDetails',
        query: {
          vid: item.id,
        },
      });
    } else {
      if (item.playTime > 300) {
        this.$router.push({
          path: '/videoDetailsPage',
          query: {
            id: item.id,
            isAcg: 0,
            kind: 9,
          },
        });
      } else {
        this.$store.commit('video/SET_SHORT_VIDEO_LIST', list);
        this.$store.commit('video/UPDATE_SHORT_VIDEO_INDEX', index);
        this.$router.push({
          path: '/shortVideoDetails',
          query: {
            isAcg: 0,
          },
        });
      }
    }
  }
}

/**
 * 跳转广告
 * @param {*} item 广告信息
 */
export function jumpAdv(item) {
  let id = item.id;
  let name = item.name;

  if (id) {
    Api(adClickReport, {
      id: id,
      adName: name,
    });
  }
  let url = item.jumpLink || item.jumpTo || item.href || item.officialUrl || item.link_url || item.link || item.linkUrl;
  let index = url.indexOf('yinseinner');
  let yinssIndex = url.indexOf('yinselinks');
  // console.log(url, "-------------");
  // return;
  if (index != -1) {
    innerJump(url.substring(13));
    return;
  }
  if (yinssIndex != -1) {
    Activity(url.substring(13));
    return;
  }
  // location = url
  //外部跳转
  setTimeout(() => {
    window.open(url);
  });
}

/**
 * 获取搜索历史
 */
export function getSearchKeys() {
  let aryKey = [];
  let aryKeyStr = sessionStorage.getItem('searchKeys');
  if (aryKeyStr != null) {
    aryKey = JSON.parse(aryKeyStr);
  }
  return aryKey;
}

/**
 * 存储搜索记录
 */
export function setSearchKey(searchValue) {
  let aryKey = getSearchKeys();
  const index = aryKey.indexOf(searchValue);
  if (index !== -1) {
    aryKey.splice(index, 1);
  }
  aryKey.unshift(searchValue);
  if (aryKey.length > 10) {
    aryKey.pop();
  }
  // aryKey.unshift(searchValue);
  sessionStorage.setItem('searchKeys', JSON.stringify(aryKey));
}

/**
 * 删除指定搜索历史
 */
export function delSearchKey(index) {
  let aryKey = getSearchKeys();
  aryKey.splice(index, 1);
  sessionStorage.setItem('searchKeys', JSON.stringify(aryKey));
}

/**
 * 清除搜索历史
 */
export function cleanSearchKey() {
  sessionStorage.setItem('searchKeys', JSON.stringify([]));
}
