//点赞 观看数量过滤器 例如超过一万就显示1.几万
export var watchCount = (value) => {
    let str = value || 0;
    if (value >= 10000) {
        let totleNum = (value / 10000).toFixed(1);
        str = `${parseFloat(totleNum)}W`;
    } else  if (value >= 1000) {
        let totleNum = (value / 1000).toFixed(1);
        str = `${parseFloat(totleNum)}K`;
    }
    return str;
}

export var watchCountAdd = (value) => {
    let str = value || 0;
    if (value >= 10000) {
        let totleNum = (value / 10000).toFixed(1);
        str = `${parseFloat(totleNum)}W +`;
    } else  if (value >= 1000) {
        let totleNum = (value / 1000).toFixed(1);
        str = `${parseFloat(totleNum)}K`;
    }
    return str;
}


export var dateago = (date) => {//评论列表里面的时间过滤器 超过一天就显示某年某月某日 没超过一天就显示几小时 几分钟前
    if (!date) return 0;
    var dateago = new Date(date);
    var dateNum = dateago.getTime();
    var separate = new Date().getTime() - dateNum;//与现在相差的时间戳
    if (separate > 86400000) {//如果时间大于一天
        return dateago.getFullYear() + '年' + (dateago.getMonth() + 1) + '月' + dateago.getDate() + '日';
    } else if (separate > 3600000) {//如果大于一个小时
        return Math.floor(separate / 3600000) + '小时前';
    } else if (separate > 60000) {//如果大于一分钟
        return Math.floor(separate / 60000) + '分钟前';
    } else {
        return '刚刚';
    }
}
export const time = (time) => {
    let date = new Date(time);
    let sdate = date.toLocaleString(undefined, {
        hour12: false,
        timeZone: "Asia/Shanghai"
    });
    return sdate;
}

// 格式化时间 yyyy.MM.dd HH:mm
export const newTime = (date) => {
    if (!date) return "";
    let newDate = new Date(date);
    let y = newDate.getFullYear();
    let m = (newDate.getMonth() + 1) < 10 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth() + 1;
    let d = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
    let h = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
    let minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
    return `${y}.${m}.${d} ${h}:${minute}`
}

export const commentTime = (date) => {
    if (!date) return "";
    let newDate = new Date(date);
    let m = (newDate.getMonth() + 1) < 10 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth() + 1;
    let d = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
    let h = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
    let minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
    return `${m}-${d} ${h}:${minute}`
}

export var videotime = (value) => {//视频时长过滤器
    if (!value) return '00:00';
    if (value >= 3600) {//如果大于一个小时
        return (Math.floor(value / 3600) < 10 ? '0' + Math.floor(value / 3600) : Math.floor(value / 3600)) + ':' + (Math.floor(value % 3600 / 60) < 10 ? '0' + Math.floor(value % 3600 / 60) : Math.floor(value % 3600 / 60)) + ':' + ((value % 3600 % 60) < 10 ? '0' + Math.floor(value % 3600 / 60) : Math.floor(value % 3600 / 60));
    } else if (value >= 60) {//如果大于一分钟
        return (Math.floor(value / 60) < 10 ? '0' + Math.floor(value / 60) : Math.floor(value / 60)) + ':' + (value % 60 < 10 ? '0' + value % 60 : value % 60);
    } else {
        return '00:' + (value < 10 ? '0' + value : value);
    }
}

export var formatTime = (time) => {
    if (!time) return '00:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

// 订单时间
export var orderDate = (date) => {
    if (!date) return "";
    let newDate = new Date(date);
    let y = newDate.getFullYear();
    let m = (newDate.getMonth() + 1) < 10 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth() + 1;
    let d = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
    let h = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
    let minute = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes();
    return `${y}-${m}-${d} ${h}:${minute}`
}

// 订单时间
export var dateYyyyMMdd = (date) => {
    if (!date) return "";
    let newDate = new Date(date);
    let y = newDate.getFullYear();
    let m = (newDate.getMonth() + 1) < 10 ? "0" + (newDate.getMonth() + 1) : newDate.getMonth() + 1;
    let d = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
    return `${y}-${m}-${d}`
}
