/**
 * 不经过action的异步
 * @param {Function} fn 异步函数
 * @param {} payload 参数
 * @param {boolean} asyncFlag 是否将请请求设置为同步请求（等待上一个请求结束，下一个请求再继续）
 * @param {Function}  callback 回调函数
 */
import moment from "moment";
import {
  addData,
  closeDB,
  cursorGetData,
  cursorGetDataByIndexAndPage,
  deleteKindDB,
  deleteDB,
  getDataByKey,
  getDataCount,
  openDB,
  UpdateData,
} from "@/utils/indexedDB";

let isPass = true;
let holdRequest = [];

export async function Api(fn, payload, asyncFlag = false, callback) {
  if (asyncFlag) {
    let ret;
    if (fn) {
      holdRequest.push(fn);
    }
    if (!isPass) return;
    isPass = false;
    if (holdRequest.length > 0) {
      let popRequest = holdRequest.pop();
      try {
        let { data } = await popRequest(payload, callback);
        ret = data;
      } catch (err) {
        ret = Promise.resolve(err);
      } finally {
        isPass = true;
        Api(undefined, undefined, true, callback);
      }
      return ret;
    }
  } else {
    let { data } = await fn(payload, callback);
    return data;
  }
}

/**
 *  截取url参数
 * @param {*} name 截取url参数名称
 * @returns
 */
export function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substring(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}

/**
 * 获取设备类型
 * @returns {string}  //设备类型
 */
export function getDevType() {
  let type = "";
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    //判断iPhone|iPad|iPod|iOS
    type = "ios";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    //判断Android
    type = "android";
  } else {
    type = "pc";
  }
  return type;
}

/**
 * 字符串首字母大写
 */
export function firstUpperCase(str) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

/**
 * 获取北京时间
 * @returns {string}
 */
export function getBeijinTime() {
  return moment().utcOffset(8).format();
}

// 保存滚动条
export async function setScrollTop(className, path, sonPath, scrollTop) {
  let db = await openDB("yjdm_db");
  let data = {
    className: className,
    path: path,
    scrollTop: scrollTop,
  };
  if (sonPath) {
    data.sonPath = sonPath;
  }
  UpdateData(db, "scrollBar", data);
}

// 保存视频历史记录
export async function addVideoHistory(data) {
  let infoData = data;
  infoData.saveTime = Date.now();
  let db = await openDB("yjdm_db", 1);
  let list = await cursorGetData(db, "mediaInfos", "saveTime", "next");
  try {
    let queryVideo = await getDataByKey(db, "mediaInfos", infoData.id);
    if (queryVideo) {
      deleteDB(db, "mediaInfos", infoData.id);
    }
    // let kindOneItems = list.filter(item => item.kind === 1);

    // if (kindOneItems.length > 5) {
    //     let idsToDelete = kindOneItems
    //         .sort((a, b) => a.saveTime - b.saveTime)
    //         .slice(0, kindOneItems.length - 5)
    //         .map(item => item.id);

    //     for (let id of idsToDelete) {
    //         deleteDB(db, "mediaInfos", id);
    //     }
    // }
    let count = await getDataCount(db, "mediaInfos");
    if (count >= 100) {
      let id = list[0].id;
      deleteDB(db, "mediaInfos", id);
    }
    addData(db, "mediaInfos", infoData);
    closeDB(db);
  } catch (error) {
    closeDB(db);
  }
}

export async function deletePost(id) {
  let db = await openDB("yjdm_db");
  try {
    deleteDB(db, "mediaInfos", id);
    closeDB(db);
    return { code: 200 };
  } catch (error) {
    closeDB(db);
    return { code: 400 };
  }
}

export async function delHistoryKind(kind) {
  let db = await openDB("yjdm_db");
  try {
    deleteKindDB(db, "mediaInfos", kind);
    closeDB(db);
    return { code: 200 };
  } catch (error) {
    closeDB(db);
    return { code: 400 };
  }
}

//查询视频历史记录
/// [kind]  0动漫 1漫画 2角色扮演 3真人 4小说 5写真 6禁播
/// [pageNumber] 页码
/// [pageSize] 页数
export async function queryHistoryVideo(page, size, kind) {
  let db = await openDB("yjdm_db");
  let list = await cursorGetDataByIndexAndPage(
    db,
    "mediaInfos",
    page,
    size,
    "prev",
    "saveTime",
    kind
  );
  return list || [];
}

//查询视频历史记录-id
export async function queryHistoryId(id) {
  let db = await openDB("yjdm_db");
  let list = await getDataByKey(db, "mediaInfos", id);
  return list;
}

/**
 * 日期时间格式化 yyyy.mm.dd hh.mm.ss
 * @param date
 */
export function formatDate(date) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

/**
 * 日期时间格式化 yyyy.mm.dd
 * @param date
 */
export function dateFormat1(date) {
  const newDate = new Date(date);
  const year = newDate.getFullYear(); // 年
  const month = showTime(newDate.getMonth() + 1); // 月
  const day = showTime(newDate.getDate()); // 日
  return year + "." + month + "." + day;
}
/**
 * 日期时间格式化 mm月dd日
 * @param date
 */
export function dateFormatMmDd(date) {
  const newDate = new Date(date);
  const month = showTime(newDate.getMonth() + 1); // 月
  const day = showTime(newDate.getDate()); // 日
  return month + "月" + day + '日';
}

/**
 * @description 相对时间并支持中文，如:1分钟前(3天以为的展示3天前)
 * @param value 需要对比的时间
 * @returns
 */
export function fromNowTimeToDate3(time) {
  if (
    (new Date().getTime() - new Date(time).getTime()) / 24 / 60 / 60 / 1000 >
    3
  ) {
    return "3天前";
  }
  moment.locale("zh-cn", {
    relativeTime: {
      future: "%s内",
      past: "%s前",
      s: "几秒",
      m: "1分钟",
      mm: "%d分钟",
      h: "1小时",
      hh: "%d小时",
      d: "1天",
      dd: "%d天",
      M: "1个月",
      MM: "%d个月",
      y: "1年",
      yy: "%d年",
    },
  });
  return moment(time).fromNow();
}

// 判断时间是否超过当前时间
export function judgeTime(date) {
  // 时间
  const date1 = new Date(date);
  // 当前时间
  const date2 = new Date();
  // 判断时间是否超出
  return date2 > date1 ? true : false;
}

// 不够两位数就补零的函数
function showTime(t) {
  const time = t >= 10 ? t : "0" + t;
  return time;
}
