const state = {
  refreshFlag: {
    novelDetailsPage: true, //小说/漫画详情页
  },
};
const mutations = {
  // 缓存页面是否刷新
  SET_REFRESHFLAG: (state,params) => {
    state.refreshFlag[params.name] = params.flag;
    console.log(state.refreshFlag[params.name]);
  },
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
