<template>
  <div class="img-decypt">
    <van-image :src="decyImg" class="vanImage swiper-zoom-container" fit="cover" :round="round" v-lazy="isLazy ? decyImg : false" @load="onLoad">
      <template v-slot:loading>
        <!-- <img :src="defaultImg" class="loading" v-if="!isSplash"> -->
        <div class="loading" v-if="!isSplash"></div>
        <div class="splash-loading-img" v-else></div>
      </template>
      <template v-slot:error>
        <!-- <img :src="defaultImg" class="loading" v-if="!isSplash"> -->
        <div class="loading" v-if="!isSplash"></div>
        <div class="splash-error-img" v-else></div>
      </template>
      <slot></slot>
    </van-image>
  </div>
</template>
<script>
import {Image as VanImage} from 'vant';
import {mapGetters} from "vuex";
import {imgDecyptApi} from "@/api/app";

export default {
  props: {
    src: {
      type: String,
      required: true,
      default() {
        return "";
      },
    },
    isNeedDecypt: {   //是否需要解密
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    isSplash: {   //是否来自启动页
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    round: {    //图片是否显示为圆形
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    isLazy: {    //图片是否懒加载
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  components: {
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      decyImg: "",
      defaultImg: require("@/assets/png/place.png")
    }
  },
  computed: {
    ...mapGetters({
      imgApi: "imgApi",
    }),
  },
  created() {
      this.init();
  },
  methods: {
    init() {
      if (!this.isNeedDecypt) {
        this.decyImg = this.src;
      } else {
        try {
          let imgApi = this.imgApi;
          if (imgApi.slice(-1) != "/") {
            imgApi = imgApi + "/";
          }
          if (!imgApi || !this.src) return;
          imgDecyptApi(imgApi + this.src).then((url) => {
            this.decyImg = url;
          });
        } catch (error) {
          console.log(error);
        }
      }
    },onLoad(){
          this.$emit("load");
      }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .van-image__loading {
  background-color: #1b164c !important;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.splash-loading-img,
.splash-error-img {
  width: 100%;
  height: 100%;
  background: url("../../assets/png/ad.png") center no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.img-decypt {
  width: 100%;
  height: 100%;

  .vanImage {
    width: 100%;
    height: 100%;
    //background-color: #e6e6e6 !important;

    .loading {
      width: 100%;
      height: 100%;
      background: #e6e6e6;
    }

    /deep/ .van-image__error {
      background-color: #e6e6e6 !important;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}
</style>
